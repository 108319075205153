export const MARKET = {
  PT: 'pt',
  IT: 'it',
  ES: 'es',
} as const;

export const PLAN = {
  WALLET: 'wallet',
  SUC_MEAL: 'suc_meal',
  ITALY: 'italy',
  ITALY_FULL: 'italy-full',
  ITALY_FRINGE: 'italy-fringe',
  ES_MEAL: 'es-meal',
  ES_WALLET: 'es-wallet',
  ES_HYBRID: 'es-hybrid',
} as const;

export type TPlan = (typeof PLAN)[keyof typeof PLAN];

export const marketPlanMap: Record<TMarket, TPlan[]> = {
  pt: [PLAN.WALLET, PLAN.SUC_MEAL],
  it: [PLAN.ITALY_FULL, PLAN.ITALY, PLAN.ITALY_FRINGE],
  es: [PLAN.ES_HYBRID, PLAN.ES_WALLET, PLAN.ES_MEAL],
} as const;

export type TMarket = (typeof MARKET)[keyof typeof MARKET];

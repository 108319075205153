// import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import '@coverflex-tech/hypernova/lib/colors/employee.css';
import '@coverflex-tech/hypernova/lib/index.css';
import App from './App';
import './i18n';
import './index.css';

async function prepare() {
  if (import.meta.env.DEV && import.meta.env.VITE_MOCK) {
    await import('./mocks/mocks.sw');
  }
}

prepare().then(() => {
  const container = document.getElementById('root');
  if (container) {
    const root = createRoot(container);
    root.render(
      // TODO: once react router is migrated we can test with strict mode on
      // <StrictMode>
      <App />
      // </StrictMode>
    );
  }
});

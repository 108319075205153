import { createContext, useContext } from 'react';
import { ERRORS } from 'constants/errors';
import { IEmployee } from 'integrations/menhir/employee/employee/employee.types';
import { ICompany } from 'types/company';

interface UserDetailsContextType {
  employee?: IEmployee;
  company?: ICompany;
  updateEmployee: (newEmployeeData: IEmployee) => void;
}

export const UserDetailsContext = createContext<UserDetailsContextType>({
  updateEmployee: () => null,
});

export const useEmployeeDetails = () => {
  const { employee, company, updateEmployee } = useContext(UserDetailsContext);

  if (!employee || !company) {
    throw new Error(ERRORS.CONTEXT_VALUE_REQUIRED);
  }

  return { employee, company, updateEmployee };
};

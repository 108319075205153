import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TextAction } from '@coverflex-tech/hypernova';
import {
  CreatePasswordForm,
  IFeedback,
} from 'containers/Authentication/components/CreatePasswordForm/CreatePasswordForm';
import CreatedAccountModal from 'containers/Authentication/components/CreatedAccountModal';
import InvalidTokenModal from 'containers/Authentication/components/InvalidTokenModal';
import { usePageViewTracking } from 'hooks/usePageViewTracking';
import { useQueryParams } from 'hooks/useQueryParams';
import { AnalyticsService } from 'integrations/analytics/analytics.service';
import { AuthService } from 'integrations/menhir/employee/sessions/sessions.service';
import { ISignInResponse } from 'integrations/menhir/employee/sessions/sessions.types';
import { AuthenticationLayout } from 'layouts/AuthenticationLayout/AuthenticationLayout';
import { logException } from 'utils/exceptionLogger';
import { Title } from 'components/Typography';
import css from './SignupForm.module.css';

interface IModal {
  type: 'success' | 'error';
  sso: boolean;
}

interface IProps {
  token: string;
  onSubmit: (response: ISignInResponse) => void;
  isSubmitting?: boolean;
}

export const SignupForm = ({ token, onSubmit, isSubmitting }: IProps) => {
  const { t } = useTranslation();
  usePageViewTracking('create_password_page');
  const { queryParams } = useQueryParams<'auth' | 'market'>();
  const [validating, setValidating] = useState(!isSubmitting);
  const [signingUp, setSigningUp] = useState(false);
  const [feedback, setFeedback] = useState<IFeedback>();
  const [modal, setModal] = useState<IModal>();

  const isSSO = queryParams.auth === 'sso';
  const market = queryParams.market;

  useEffect(() => {
    (async () => {
      if (isSubmitting) return;
      setValidating(true);
      try {
        await AuthService.validateInvitation(token);
        setModal({
          type: 'success',
          sso: true,
        });
      } catch (error: any) {
        if (error?.errorCode === 'not_found') {
          setModal({
            type: 'error',
            sso: isSSO,
          });
        }
      } finally {
        setValidating(false);
      }
    })();
  }, [isSubmitting, token, isSSO, t]);

  const handleSubmit = async (form: {
    token: string;
    password: string;
    passwordConfirmation: string;
  }) => {
    AnalyticsService.trackCustomEvent('create_password_button_clicked');
    const { token, password, passwordConfirmation } = form;

    if (password !== passwordConfirmation) return;

    setSigningUp(true);
    try {
      const response = await AuthService.signUp(token, password);
      onSubmit(response);
    } catch (e: any) {
      if (e?.errorCode === 'not_found') {
        setFeedback({
          type: 'error',
          message: t('errors.api.auth.invalid_token'),
        });
      }
    } finally {
      setSigningUp(false);
    }
  };

  if (validating && !isSubmitting) {
    return null;
  }

  if (!market) {
    logException(
      'The url for the user signup does not contain the market query parameter.'
    );
  }

  const disclaimer = (
    <div data-test-id={`terms-of-use-${market}`}>
      <Trans
        i18nKey="authentication.create_password.terms_of_use_and_privacy_policy"
        components={{
          termsOfUse: (
            <TextAction
              as="a"
              href={t(
                `general.links.terms_of_use.${market ? market : 'pt'}.url`
              )}
              target="_blank"
              rel="noreferrer"
            />
          ),
          privacyPolicy: (
            <TextAction
              as="a"
              href={t(
                `general.links.privacy_policy.${market ? market : 'pt'}.url`
              )}
              target="_blank"
              rel="noreferrer"
            />
          ),
        }}
      />
    </div>
  );

  return (
    <AuthenticationLayout>
      <div className={css.host}>
        {modal?.type === 'success' && <CreatedAccountModal sso={modal.sso} />}
        {modal?.type === 'error' && <InvalidTokenModal sso={modal.sso} />}
        <Title size="small" className={css.title}>
          {t('authentication.signup.form.title')}
        </Title>
        <CreatePasswordForm
          token={token}
          feedback={feedback}
          buttonLabel={t('authentication.signup.form.button')}
          submitting={signingUp || isSubmitting}
          onSubmit={handleSubmit}
          disclaimer={disclaimer}
        />
      </div>
    </AuthenticationLayout>
  );
};

export const PhoneAsset = () => (
  <svg
    width="216"
    height="212"
    viewBox="0 0 216 212"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.901 0H112.772C123.759 0 132.673 8.89736 132.673 19.8639V42.2676C128.441 47.8697 126.016 54.7937 125.825 62.0214V84.5722C121.763 90.6555 119.366 97.7945 118.897 105.211C118.428 112.628 119.903 120.038 123.163 126.634C125.528 131.419 128.766 135.643 132.673 139.075V192.017C132.673 202.984 123.759 211.881 112.772 211.881H19.901C8.91399 211.881 0 202.984 0 192.017V19.8639C0 8.89736 8.91399 0 19.901 0ZM53.0693 185.396C53.0693 192.721 58.9981 198.639 66.3366 198.639C73.6751 198.639 79.604 192.721 79.604 185.396C79.604 178.071 73.6751 172.153 66.3366 172.153C58.9981 172.153 53.0693 178.071 53.0693 185.396ZM107.797 158.911C110.533 158.911 112.772 156.676 112.772 153.945V24.8298C112.772 22.0985 110.533 19.8639 107.797 19.8639H24.8762C22.1399 19.8639 19.901 22.0985 19.901 24.8298V153.945C19.901 156.676 22.1399 158.911 24.8762 158.911H107.797Z"
        fill="#7E8E9F"
        fillOpacity="0.25"
      />
    </g>
    <g filter="url(#filter0_d_23994_147546)">
      <path
        d="M193.706 86.0919V66.172C193.5 58.5282 190.422 51.269 185.126 45.9379C179.83 40.6067 172.734 37.624 165.346 37.624C157.959 37.624 150.863 40.6067 145.567 45.9379C140.271 51.269 137.192 58.5282 136.986 66.172V86.0919C133.341 91.4654 131.191 97.7716 130.769 104.323C130.348 110.875 131.672 117.42 134.598 123.246C137.523 129.073 141.937 133.956 147.359 137.364C152.781 140.772 159.003 142.575 165.346 142.575C171.69 142.575 177.912 140.772 183.334 137.364C188.756 133.956 193.17 129.073 196.095 123.246C199.02 117.42 200.345 110.875 199.923 104.323C199.502 97.7716 197.352 91.4654 193.706 86.0919ZM169.458 106.628V118.037C169.452 118.432 169.296 118.809 169.024 119.087C168.752 119.364 168.385 119.52 168.003 119.52H162.712C162.33 119.52 161.963 119.364 161.691 119.087C161.419 118.809 161.263 118.432 161.257 118.037V106.628C160.038 105.73 159.127 104.455 158.654 102.986C158.181 101.518 158.172 99.9312 158.627 98.4564C159.082 96.9817 159.978 95.6948 161.186 94.7817C162.394 93.8685 163.851 93.3765 165.346 93.3765C166.842 93.3765 168.299 93.8685 169.507 94.7817C170.715 95.6948 171.611 96.9817 172.066 98.4564C172.521 99.9312 172.512 101.518 172.039 102.986C171.566 104.455 170.654 105.73 169.436 106.628H169.458ZM182.684 75.7098C177.441 72.5335 171.478 70.8517 165.402 70.8357C159.327 70.8197 153.356 72.4699 148.097 75.6185V66.172C148.257 61.547 150.144 57.1667 153.361 53.9532C156.579 50.7398 160.875 48.9441 165.346 48.9441C169.817 48.9441 174.114 50.7398 177.331 53.9532C180.549 57.1667 182.436 61.547 182.596 66.172L182.684 75.7098Z"
        fill="url(#paint0_linear_23994_147546)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_23994_147546"
        x="114.693"
        y="29.624"
        width="101.307"
        height="136.95"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="8" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.541176 0 0 0 0 0.788235 0 0 0 0 0.905882 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_23994_147546"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_23994_147546"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_23994_147546"
        x1="137.19"
        y1="46.3699"
        x2="220.68"
        y2="104.349"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BCDAE5" />
        <stop offset="1" stopColor="#CBE2DA" />
      </linearGradient>
    </defs>
  </svg>
);

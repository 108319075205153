import { IconProps } from 'types/general';

export function PlayStoreBadge({
  width = 142,
  height = 42,
  className,
}: IconProps) {
  return (
    <svg width={width} height={height} className={className} fill="none">
      <path
        fill="#100F0D"
        d="M136.31 42H6.04a5.25 5.25 0 0 1-5.21-5.25V5.25A5.25 5.25 0 0 1 6.03 0h130.28a5.25 5.25 0 0 1 5.22 5.25v31.5A5.25 5.25 0 0 1 136.3 42Z"
      />
      <path
        fill="#A2A2A1"
        d="M136.31 0H6.04A5.25 5.25 0 0 0 .83 5.25v31.5A5.25 5.25 0 0 0 6.03 42h130.28a5.25 5.25 0 0 0 5.22-5.25V5.25A5.25 5.25 0 0 0 136.3 0Zm0 .84a4.4 4.4 0 0 1 4.38 4.41v31.5a4.4 4.4 0 0 1-4.38 4.41H6.04a4.4 4.4 0 0 1-4.38-4.41V5.25A4.4 4.4 0 0 1 6.04.84h130.28Z"
      />
      <path
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth=".27"
        d="M50.2 10.29h-3.03v.75h2.27a2.1 2.1 0 0 1-.72 1.45c-.41.35-.93.53-1.55.53-.68 0-1.26-.24-1.73-.71a2.5 2.5 0 0 1-.7-1.8c0-.73.24-1.32.7-1.8a2.34 2.34 0 0 1 1.73-.72c.35 0 .68.06.98.2.3.13.55.31.74.55l.58-.58c-.26-.3-.6-.53-1-.7a3.42 3.42 0 0 0-1.3-.24c-.9 0-1.68.31-2.3.95a3.2 3.2 0 0 0-.95 2.33c0 .93.32 1.7.95 2.34.62.63 1.4.95 2.3.95.95 0 1.7-.3 2.3-.93.5-.52.77-1.22.77-2.1 0-.15-.02-.3-.05-.47Zm1.2-2.93v6.29h3.64v-.77H52.2v-2h2.57v-.75H52.2v-2h2.84v-.77H51.4Zm8.78.77v-.77h-4.3v.77h1.75v5.52h.8V8.13h1.75Zm3.92-.77h-.8v6.29h.8v-6.3Zm5.3.77v-.77h-4.29v.77h1.75v5.52h.8V8.13h1.74Zm8.13.05a3.04 3.04 0 0 0-2.28-.96c-.91 0-1.67.31-2.29.95-.62.62-.92 1.4-.92 2.33 0 .94.3 1.72.92 2.34.62.63 1.38.95 2.29.95.9 0 1.66-.32 2.28-.95.62-.62.93-1.4.93-2.34 0-.92-.3-1.7-.93-2.32Zm-4 .52a2.28 2.28 0 0 1 1.72-.7c.68 0 1.24.23 1.7.7.46.47.69 1.07.69 1.8s-.23 1.34-.7 1.8c-.45.48-1.01.72-1.7.72-.67 0-1.24-.24-1.7-.71a2.5 2.5 0 0 1-.68-1.8c0-.74.22-1.33.68-1.8Zm6.86 1.03-.03-1.21h.03l3.18 5.13h.84v-6.3h-.8v3.69l.03 1.21h-.04l-3.03-4.9h-.98v6.3h.8V9.73Z"
      />
      <path
        fill="#fff"
        d="M112.28 31.5h1.94V18.37h-1.94V31.5Zm17.51-8.4-2.22 5.7h-.07l-2.32-5.7h-2.1l3.48 7.96-1.98 4.42h2.03l5.35-12.38h-2.17ZM118.76 30c-.63 0-1.52-.31-1.52-1.1 0-1.02 1.1-1.4 2.06-1.4.86 0 1.26.18 1.78.43a2.36 2.36 0 0 1-2.32 2.08Zm.24-7.18c-1.4 0-2.87.62-3.47 2l1.73.73c.36-.73 1.05-.96 1.77-.96 1 0 2.03.6 2.05 1.69v.13c-.35-.2-1.1-.5-2.03-.5-1.86 0-3.75 1.03-3.75 2.95 0 1.76 1.52 2.89 3.23 2.89 1.3 0 2.03-.6 2.48-1.29h.07v1.02h1.88v-5.03c0-2.33-1.73-3.63-3.96-3.63Zm-12.02 1.88h-2.77v-4.5h2.77a2.27 2.27 0 0 1 2.28 2.25c0 1.02-.83 2.25-2.28 2.25Zm-.05-6.33h-4.66V31.5h1.95v-4.97h2.71c2.16 0 4.27-1.57 4.27-4.08 0-2.5-2.11-4.08-4.27-4.08ZM81.52 30.01c-1.34 0-2.47-1.13-2.47-2.69 0-1.57 1.13-2.72 2.47-2.72 1.33 0 2.37 1.15 2.37 2.72 0 1.56-1.04 2.7-2.37 2.7Zm2.23-6.17h-.06c-.44-.53-1.28-1-2.34-1a4.43 4.43 0 0 0-4.25 4.48 4.4 4.4 0 0 0 4.25 4.45c1.06 0 1.9-.47 2.34-1.01h.06v.64c0 1.7-.9 2.62-2.36 2.62-1.2 0-1.93-.86-2.24-1.59l-1.7.71a4.22 4.22 0 0 0 3.94 2.64c2.28 0 4.21-1.35 4.21-4.65V23.1h-1.85v.73Zm3.2 7.66h1.94V18.37h-1.95V31.5Zm4.81-4.33a2.44 2.44 0 0 1 2.32-2.6c.77 0 1.43.38 1.65.94l-3.97 1.66Zm6.05-1.49c-.37-1-1.5-2.84-3.8-2.84-2.28 0-4.18 1.8-4.18 4.46 0 2.5 1.88 4.47 4.4 4.47a4.4 4.4 0 0 0 3.7-1.98l-1.52-1.01c-.5.74-1.19 1.23-2.18 1.23-.99 0-1.7-.46-2.15-1.35l5.93-2.47-.2-.5Zm-47.22-1.47v1.9h4.5a3.97 3.97 0 0 1-1.03 2.38 4.6 4.6 0 0 1-3.47 1.39 4.96 4.96 0 0 1-4.94-5.04 4.96 4.96 0 0 1 8.33-3.7l1.32-1.33a6.54 6.54 0 0 0-4.71-1.91 7 7 0 0 0-6.99 6.94 7 7 0 0 0 6.99 6.93c2.04 0 3.59-.68 4.8-1.94a6.29 6.29 0 0 0 1.63-4.44c0-.44-.04-.84-.1-1.18h-6.33ZM62.13 30c-1.34 0-2.5-1.11-2.5-2.7 0-1.61 1.16-2.71 2.5-2.71 1.35 0 2.5 1.1 2.5 2.7s-1.15 2.71-2.5 2.71Zm0-7.17a4.39 4.39 0 0 0-4.45 4.46c0 2.58 2 4.47 4.45 4.47a4.4 4.4 0 0 0 4.45-4.46c0-2.6-2-4.47-4.45-4.47Zm9.7 7.17c-1.33 0-2.5-1.11-2.5-2.7 0-1.61 1.17-2.71 2.5-2.71 1.35 0 2.51 1.1 2.51 2.7s-1.16 2.71-2.5 2.71Zm0-7.17a4.39 4.39 0 0 0-4.44 4.46c0 2.58 2 4.47 4.45 4.47a4.4 4.4 0 0 0 4.45-4.46c0-2.6-2-4.47-4.45-4.47Z"
      />
      <path
        fill="#EB3131"
        d="m22.42 20.4-11.1 11.86a3 3 0 0 0 4.42 1.83l.03-.02 12.5-7.26-5.85-6.41Z"
      />
      <path
        fill="#F6B60B"
        d="M33.64 18.38v-.01l-5.4-3.15-6.07 5.44 6.1 6.15 5.36-3.12a3.02 3.02 0 0 0 .01-5.31Z"
      />
      <path
        fill="#5778C5"
        d="M11.32 9.74c-.06.25-.1.5-.1.78v20.96c0 .27.03.53.1.78L22.8 20.7 11.32 9.74Z"
      />
      <path
        fill="#3BAD49"
        d="m22.5 21 5.74-5.78-12.47-7.3a2.99 2.99 0 0 0-4.45 1.82L22.5 21Z"
      />
    </svg>
  );
}

import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { queryClient } from 'contexts/react-query/ReactQueryProvider';
import {
  CardStatus,
  ICard,
} from 'integrations/menhir/employee/card/card.types';
import { MENHIR_ENDPOINTS } from 'integrations/menhir/menhir.endpoints';
import { IAddress } from 'types/general';
import { CardService, validateCardName } from './card.service';

export const useValidateCardName = (
  options?: UseMutationOptions<unknown, unknown, { cardHolderName: string }>
) => {
  return useMutation({
    mutationFn: ({ cardHolderName }) => validateCardName(cardHolderName),
    ...options,
  });
};

export function useGetCard(options?: UseQueryOptions<ICard, any, ICard>) {
  const { data: card, ...rest } = useQuery({
    queryKey: [MENHIR_ENDPOINTS.EMPLOYEE.CARD.ROOT],
    queryFn: CardService.getEmployeeCard,
    ...options,
  });
  return { card, ...rest };
}

export function resetGetCardQueryData() {
  return queryClient.resetQueries([MENHIR_ENDPOINTS.EMPLOYEE.CARD.ROOT]);
}

export function useRequestCard(
  options?: UseMutationOptions<
    ICard,
    unknown,
    {
      address: IAddress;
      cardHolderName?: string;
    }
  >
) {
  return useMutation({
    mutationFn: ({ address, cardHolderName }) =>
      CardService.requestCard(address, cardHolderName),
    ...options,
  });
}

export function useCancelCard(options?: UseMutationOptions<CardStatus>) {
  return useMutation({
    mutationFn: CardService.cancelCard,
    onSuccess: onStatusChange,
    ...options,
  });
}

export function useLockCard(options?: UseMutationOptions<CardStatus>) {
  return useMutation({
    mutationFn: CardService.lockCard,
    onSuccess: onStatusChange,
    ...options,
  });
}

export function useUnlockCard(options?: UseMutationOptions<CardStatus>) {
  return useMutation({
    mutationFn: CardService.unlockCard,
    onSuccess: onStatusChange,
    ...options,
  });
}

export function useActivateCard(
  options?: UseMutationOptions<
    CardStatus,
    unknown,
    {
      panCode: string;
    }
  >
) {
  return useMutation({
    mutationFn: ({ panCode }) => CardService.activateCard(panCode),
    onSuccess: onStatusChange,
    ...options,
  });
}

export function useRequestCardPin(options?: UseMutationOptions) {
  return useMutation({
    mutationFn: CardService.requestPin,
    ...options,
  });
}

export function useRequestPinToken(
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof CardService.requestPinToken>>,
    any
  >
) {
  return useMutation({
    mutationFn: CardService.requestPinToken,
    ...options,
  });
}

export function useResetPin(
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof CardService.resetPin>>,
    any,
    Parameters<typeof CardService.resetPin>[0]
  >
) {
  return useMutation({
    mutationFn: CardService.resetPin,
    ...options,
  });
}

const onStatusChange = (newStatus: CardStatus) => {
  queryClient.setQueryData<ICard | null>(
    [MENHIR_ENDPOINTS.EMPLOYEE.CARD.ROOT],
    (card) => {
      if (card) {
        return {
          ...card,
          status: newStatus,
        };
      }
    }
  );
};

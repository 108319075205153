import { ComponentType, lazy } from 'react';

const KEY = 'cvrflx-flightdeck:lazy-refreshed';

export const safeLazy = <T extends {}, U extends keyof T>(
  loader: (x?: string) => Promise<T>
) =>
  new Proxy({} as unknown as T, {
    get: (_, componentName: string | symbol) => {
      if (typeof componentName === 'string') {
        return lazy(() => {
          return new Promise<{
            default: ComponentType<any>;
          }>((resolve, reject) => {
            const hasRefreshed = JSON.parse(
              window.sessionStorage.getItem(KEY) || 'false'
            );
            loader(componentName)
              .then((x) => {
                window.sessionStorage.setItem(KEY, 'false');
                const component = {
                  default: x[componentName as U] as any as ComponentType<any>,
                };
                resolve(component);
                return component;
              })
              .catch((error) => {
                if (!hasRefreshed) {
                  window.sessionStorage.setItem(KEY, 'true');
                  return window.location.reload();
                }
                reject(error);
              });
          });
        });
      }
    },
  });

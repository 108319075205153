import { Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import { ERRORS } from 'constants/errors';
import { MARKET, PLAN, marketPlanMap } from 'constants/markets';
import { useEmployeeDetails } from 'contexts/user/UserDetailsContext';
import { useHomeRedirect } from 'hooks/useHomeRedirect';
import { ICompany } from 'types/company';
import { safeLazy } from 'utils/import';
import { LoadingPlaceholder } from 'components/LoadingPlaceholder/LoadingPlaceholder';

const { ITDashboardRouter } = safeLazy(
  () => import('./ITDashboard/ITDashboardRouter')
);
const { PTMealDashboardRouter } = safeLazy(
  () => import('./PTMealDashboard/PTMealDashboardRouter')
);
const { PTWalletDashboardRouter } = safeLazy(
  () => import('./PTWalletDashboard/PTWalletDashboardRouter')
);
const { ESMealDashboardRouter } = safeLazy(
  () => import('./ESMealDashboard/ESMealDashboardRouter')
);
const { ESWalletDashboardRouter } = safeLazy(
  () => import('./ESWalletDashboard/ESWalletDashboardRouter')
);

export const Dashboard = () => {
  const { company } = useEmployeeDetails();
  const { route, loadingRoute } = useHomeRedirect();

  if (loadingRoute) {
    return <LoadingPlaceholder />;
  }

  if (route) {
    return <Redirect to={route} />;
  }

  return (
    <Suspense fallback={<LoadingPlaceholder />}>
      {getDashboardComponent(company)}
    </Suspense>
  );
};

const getDashboardComponent = (company: ICompany) => {
  if (company.market.slug === MARKET.PT) {
    if (company.settings?.plan === PLAN.WALLET) {
      return <PTWalletDashboardRouter />;
    }
    if (company.settings?.plan === PLAN.SUC_MEAL) {
      return <PTMealDashboardRouter />;
    }
    throw new Error(ERRORS.PLAN_NOT_SUPPORTED);
  }

  if (company.market.slug === MARKET.IT) {
    if (marketPlanMap[MARKET.IT].includes(company.settings.plan)) {
      return <ITDashboardRouter plan={company.settings.plan} />;
    }
    throw new Error(ERRORS.PLAN_NOT_SUPPORTED);
  }

  if (company.market.slug === MARKET.ES) {
    if (
      company.settings?.plan === PLAN.ES_WALLET ||
      company.settings?.plan === PLAN.ES_HYBRID
    ) {
      return <ESWalletDashboardRouter />;
    }
    if (company.settings?.plan === PLAN.ES_MEAL) {
      return <ESMealDashboardRouter />;
    }
    throw new Error(ERRORS.PLAN_NOT_SUPPORTED);
  }

  throw new Error(ERRORS.MARKET_NOT_SUPPORTED);
};

import { LanguageType } from 'types/general';

export const LANG = {
  EN: 'en-GB',
  IT: 'it-IT',
  PT: 'pt-PT',
  ES: 'es-ES',
} as const satisfies Record<string, LanguageType>;

export const LANGUAGES: LanguageType[] = Object.values(LANG);

import { createContext } from 'react';
import { IUser } from 'integrations/menhir/employee/sessions/sessions.types';

interface AuthContextType {
  user?: IUser;
  signIn: (newUser: any) => void;
  signOut: () => void;
  initialRoute?: string;
}

const AuthContext = createContext<AuthContextType>({
  signIn: () => null,
  signOut: () => null,
});

export default AuthContext;

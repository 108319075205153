import { ReactNode, useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import AuthContext from 'contexts/authentication/AuthContext';
import UserDetailsProvider from 'contexts/user/UserDetailsProvider';
import { AuthService } from 'integrations/menhir/employee/sessions/sessions.service';
import { LoadingPlaceholder } from 'components/LoadingPlaceholder/LoadingPlaceholder';
import { MobileExperienceModal } from './MobileExperienceModal/MobileExperienceModal';

interface IProps extends RouteProps {
  requireOnboarding?: boolean;
  children: ReactNode | ReactNode[];
}

export const PrivateRoute = ({
  children,
  requireOnboarding,
  ...props
}: IProps) => {
  const { user } = useContext(AuthContext);
  return (
    <Route
      {...props}
      render={() => {
        const isAuthenticated = AuthService.checkIfAuthenticated();

        if (!isAuthenticated) {
          return <Redirect push to={ROUTES.SIGNIN} />;
        }
        if (!user) {
          return <LoadingPlaceholder />;
        }

        return (
          <UserDetailsProvider user={user}>
            {children}
            <MobileExperienceModal />
          </UserDetailsProvider>
        );
      }}
    />
  );
};

import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GenericModal, Heading, Text } from '@coverflex-tech/hypernova';
import { ROUTES } from 'constants/routes';
import { CreatePasswordForm } from 'containers/Authentication/components/CreatePasswordForm/CreatePasswordForm';
import { usePageViewTracking } from 'hooks/usePageViewTracking';
import { AnalyticsService } from 'integrations/analytics/analytics.service';
import { AuthService } from 'integrations/menhir/employee/sessions/sessions.service';

interface IProps {
  token: string;
}

export const ConfirmReset = ({ token }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  usePageViewTracking('reset_password_page');
  const handleSubmit = useCallback(
    async (form: {
      password: string;
      passwordConfirmation: string;
      token: string;
    }) => {
      AnalyticsService.trackCustomEvent('reset_password_button_clicked');
      try {
        setSubmitting(true);
        await AuthService.passwordReset(token, form.password);
        history.push(ROUTES.SIGNIN);
      } catch {
        setShowModal(true);
        setSubmitting(false);
      }
    },
    [token, history]
  );

  return (
    <>
      <Heading level={3}>
        {t('authentication.reset_password.confirm.title')}
      </Heading>
      <CreatePasswordForm
        token={token}
        buttonLabel={t('authentication.reset_password.confirm.button')}
        submitting={submitting}
        onSubmit={handleSubmit}
      />
      {showModal && (
        <GenericModal
          onClose={() => {
            setShowModal(false);
          }}
          title={t('authentication.reset_password.confirm.modal.title')}
          content={
            <>
              <Text bold>
                {t('authentication.reset_password.confirm.modal.subtitle')}
              </Text>
              <Text>
                {t('authentication.reset_password.confirm.modal.description')}
              </Text>
            </>
          }
          primaryAction={{
            onClick: () => {
              history.push(ROUTES.RESET_PASSWORD);
            },
            children: t('authentication.reset_password.confirm.modal.button'),
          }}
        />
      )}
    </>
  );
};

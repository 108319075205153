import { useEffect, useState } from 'react';
import { AnalyticsService } from 'integrations/analytics/analytics.service';
import { ITrackingPageViewTraits, ITrackingPageViewType } from 'types/tracking';

export function usePageViewTracking<T extends ITrackingPageViewType>(
  pageName: T,
  metadata?: ITrackingPageViewTraits[T]
) {
  const [executed, setExecuted] = useState(false);

  useEffect(() => {
    if (!executed) {
      AnalyticsService.trackPageView(pageName, metadata);
      setExecuted(true);
    }
  }, [pageName, metadata, executed]);
}

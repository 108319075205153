import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import {
  Checkbox,
  type ICheckboxDescriptiveProps,
  type ICheckboxSimpleProps,
} from '@coverflex-tech/hypernova';

export const ControlledCheckbox = <T extends FieldValues>({
  name,
  control,
  transform = (val: boolean) => val,
  ...restProps
}: IProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, formState: { isSubmitting } }) => (
        <Checkbox
          {...field}
          id={name}
          name={name}
          onChange={() => {
            field.onChange(transform(!field.value));
          }}
          checked={Boolean(field.value)}
          disabled={isSubmitting}
          {...restProps}
        />
      )}
    />
  );
};

type IProps<T extends FieldValues> = (
  | Omit<ICheckboxSimpleProps, 'id' | 'name' | 'checked' | 'onChange'>
  | Omit<ICheckboxDescriptiveProps, 'id' | 'name' | 'checked' | 'onChange'>
) & {
  name: Path<T>;
  control?: Control<T>;
  transform?: (value: boolean) => boolean;
};

const ERROR = {
  USER_ALREADY_EXISTS: 'user_already_exists',
  UNAUTHORIZED: 'unauthorized',
};

export const ERROR_I18N = {
  [ERROR.USER_ALREADY_EXISTS]: 'errors.api.auth.user_already_exists',
  [ERROR.UNAUTHORIZED]: 'errors.api.auth.unauthorized',
};

export const ERRORS = {
  MARKET_NOT_SUPPORTED: 'MARKET_NOT_SUPPORTED',
  PLAN_NOT_SUPPORTED: 'PLAN_NOT_SUPPORTED',
  EMPLOYEE_REQUIRED: 'EMPLOYEE_REQUIRED',
  COMPANY_REQUIRED: 'COMPANY_REQUIRED',
  CONTEXT_VALUE_REQUIRED: 'CONTEXT_VALUE_REQUIRED',
} as const;

export const SERVER_ERRORS = {
  CARD_NOT_FOUND: 'card_not_found',
} as const;

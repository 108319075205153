import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { browserHistory } from 'browser-history';
import { ROUTES } from 'constants/routes';
import { getDefaultLang } from 'i18n';
import { LocalStorageService } from 'integrations/local-storage/local-storage.service';
import { AuthService } from 'integrations/menhir/employee/sessions/sessions.service';
import { MENHIR_ENDPOINTS } from 'integrations/menhir/menhir.endpoints';

interface IRenewTokenResponse {
  data: {
    data: {
      access_token: string;
      refresh_token: string;
    };
  };
}

const BASE_URL = `${import.meta.env.VITE_API_HOST || ''}/api`;

export const menhirClient = axios.create({
  baseURL: BASE_URL,
});

export const menhirClientVanilla = axios.create({
  baseURL: BASE_URL,
});

let pendingRenewRequest: Promise<IRenewTokenResponse> | null = null;
const refreshToken = async (error: AxiosError) => {
  const { config } = error;

  if (
    config.url?.includes(MENHIR_ENDPOINTS.EMPLOYEE.SESSIONS.RENEW) ||
    config.url?.includes('/sessions') ||
    config.url?.includes('/registration')
  )
    return;

  const refreshToken = LocalStorageService.getRefreshToken();

  if (!pendingRenewRequest) {
    pendingRenewRequest = axios.post<null, IRenewTokenResponse>(
      `${BASE_URL}${MENHIR_ENDPOINTS.EMPLOYEE.SESSIONS.RENEW}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
        timeout: 5000,
      }
    );
  }

  const { data } = await pendingRenewRequest;
  pendingRenewRequest = null;

  const {
    data: { access_token, refresh_token },
  } = data;

  LocalStorageService.updateTokens(access_token, refresh_token);
  return access_token;
};

export const requestInterceptor = (config: AxiosRequestConfig) => {
  const authToken = LocalStorageService.getAuthToken();

  if (authToken) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${authToken}`,
        'x-coverflex-version': '1.0.2',
        'x-coverflex-channel': 'web',
        'x-coverflex-language':
          LocalStorageService.getLanguage() ?? getDefaultLang(),
      },
    };
  }

  return config;
};

export const responseSuccessInterceptor = (response: AxiosResponse) =>
  response.data;

export const responseErrorInterceptor = async (error: AxiosError) => {
  const { response, config } = error;
  if (response) {
    if (response.status === 401) {
      try {
        const authToken = await refreshToken(error);
        if (authToken) {
          return menhirClient.request(config);
        }
      } catch {
        if (pendingRenewRequest) {
          AuthService.signOut();
          browserHistory.push(ROUTES.SIGNIN, { sessionExpired: true });
          pendingRenewRequest = null;
        }
      }
    }

    const { data } = response;

    return Promise.reject({
      statusCode: response?.status,
      errorCode: data.errors?.status,
      errors: data.errors ?? null,
    });
  }
};

menhirClient.interceptors.request.use(requestInterceptor);
menhirClient.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor
);

menhirClientVanilla.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    return Promise.reject({
      statusCode: error.response?.status,
      errorCode: error.response?.data.errors?.status,
      errors: error.response?.data.errors ?? null,
    });
  }
);

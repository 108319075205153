import {
  IAppleOrderItem,
  IAppleProduct,
} from 'integrations/menhir/employee/marketplace/marketplace.types';

export const getProductAppleCare = (product: IAppleProduct) =>
  product.addOns.find((addOn) => addOn.type === 'apple_care');

export const getOrderItemAppleCare = (orderItem: IAppleOrderItem) =>
  orderItem.addOnItems.find(
    (addOnItem) => addOnItem.addOn.type === 'apple_care'
  );

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconBadge,
  IconMobile,
  Modal,
  ModalContent,
  ModalHeader,
  Text,
} from '@coverflex-tech/hypernova';
import { TABLET_LANDSCAPE } from 'constants/breakpoints';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { AppStoreBadge } from 'components/Icons/AppStoreBadge';
import { PlayStoreBadge } from 'components/Icons/PlayStoreBadge';
import { css } from './MobileExperienceModal.css';

export function MobileExperienceModal() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(TABLET_LANDSCAPE);
  const [discarded, setDiscarded] = useState(false);

  if (!isMobile || discarded) {
    return null;
  }

  return (
    <Modal className={css.root} onClose={() => setDiscarded(true)}>
      <ModalHeader
        iconBadge={
          <IconBadge icon={<IconMobile width={24} height={24} />} ratio={56} />
        }
        title={t('mobile_experience_modal.title')}
      />
      <ModalContent>
        <Text color="neutral20" className={css.description}>
          {t('mobile_experience_modal.description')}
        </Text>
        <div className={css.actions}>
          <a
            href="https://play.google.com/store/apps/details?id=com.coverflex"
            target="_blank"
            rel="noreferrer"
          >
            <PlayStoreBadge className={css.badge} />
          </a>
          <a
            href="https://apps.apple.com/app/coverflex/id1534924147"
            target="_blank"
            rel="noreferrer"
          >
            <AppStoreBadge className={css.badge} />
          </a>
        </div>
      </ModalContent>
    </Modal>
  );
}

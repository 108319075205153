import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useQueryParams<T extends string>() {
  type TSearchParams = TSearchParameters<T>;
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const queryParams = useMemo(
    () => Object.fromEntries(searchParams.entries()) as TSearchParams,
    [searchParams]
  );

  const setQueryParams = useCallback(
    (
      params: ((oldParams?: TSearchParams) => TSearchParams) | TSearchParams
    ) => {
      const newParams =
        typeof params === 'function' ? params(queryParams) : params;
      Object.entries(newParams).forEach(([key, value]) => {
        if (value) {
          searchParams.set(key, value as string);
        } else {
          searchParams.delete(key);
        }
      });
      history.replace({ search: searchParams.toString() });
    },
    [searchParams, queryParams, history]
  );

  return {
    searchParams,
    queryParams,
    setQueryParams,
  };
}

export type TSearchParameters<T extends string> = Partial<
  Record<T, string | undefined>
>;

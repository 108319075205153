import { ReactElement, Suspense } from 'react';
import { Route } from 'react-router-dom';
import { LoadingPlaceholder } from 'components/LoadingPlaceholder/LoadingPlaceholder';
import { PrivateRoute } from 'components/PrivateRoute/PrivateRoute';

interface ISuspenseRouteProps {
  path: string;
  exact?: boolean;
  isPrivate?: boolean;
  children: ReactElement;
}

const SuspenseRoute = ({
  path,
  exact,
  isPrivate,
  children,
}: ISuspenseRouteProps) => {
  if (isPrivate) {
    return (
      <PrivateRoute exact={exact} path={path}>
        <Suspense fallback={<LoadingPlaceholder />}>{children}</Suspense>
      </PrivateRoute>
    );
  }

  return (
    <Route exact={exact} path={path}>
      <Suspense fallback={<LoadingPlaceholder />}>{children}</Suspense>
    </Route>
  );
};

export default SuspenseRoute;

import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getAvailableFeatures } from 'integrations/menhir/employee/features/features.service';
import { IFeatures } from 'integrations/menhir/employee/features/features.types';
import { MENHIR_ENDPOINTS } from 'integrations/menhir/menhir.endpoints';

export const useGetFeatures = (config: UseQueryOptions<IFeatures> = {}) => {
  const { data: features = DEFAULT_FEATURES, ...rest } = useQuery<IFeatures>({
    queryKey: [MENHIR_ENDPOINTS.EMPLOYEE.FEATURES],
    queryFn: getAvailableFeatures,
    keepPreviousData: true,
    staleTime: 1000 * 30,
    ...config,
  });
  return { features, ...rest };
};

export const DEFAULT_FEATURES: IFeatures = {
  FF_BENEFITS_OPTIN: false,
  FF_BUDGET_WITH_APPLICATION: false,
  FF_BUONI_PASTO_NETWORK: false,
  FF_CARD: false,
  FF_DISCOUNTS: false,
  FF_EMPLOYEE_CREDITS: false,
  FF_FILE_BANKING_PROVIDER: false,
  FF_RECARDING_IT: false,
  FF_REIMBURSEMENTS_MULTIPLE_FILES_UPLOAD: false,
  FF_SHOW_BANNER_FOR_RETIREMENT_INVESTMENT: false,
  FF_CAN_ADD_FAMILY_MEMBERS: false,
};

import { transformFeatures } from 'integrations/menhir/employee/features/features.dto';
import {
  IFeatures,
  IMenhirFeatures,
} from 'integrations/menhir/employee/features/features.types';
import { menhirClient } from 'integrations/menhir/menhir.client';
import { MENHIR_ENDPOINTS } from 'integrations/menhir/menhir.endpoints';

export const getAvailableFeatures = (): Promise<IFeatures> =>
  menhirClient
    .get<null, { features: IMenhirFeatures }>(
      MENHIR_ENDPOINTS.EMPLOYEE.FEATURES
    )
    .then((envelope) => transformFeatures(envelope.features));

import {
  IExternalIntegrations,
  IMenhirExternalIntegrations,
} from 'integrations/menhir/external-integrations/external-integrations.types';

export const transformExternalIntegrations = (
  integrations: IMenhirExternalIntegrations
): IExternalIntegrations => ({
  zendesk: {
    liveChatToken: integrations?.zendesk?.live_chat_token ?? '',
  },
});

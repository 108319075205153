import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FloatingDigitsField,
  type IFloatingDigitsFieldProps,
} from '@coverflex-tech/hypernova';

type IProps<T extends FieldValues> = Omit<
  IFloatingDigitsFieldProps,
  'id' | 'value' | 'onChange'
> & {
  name: Path<T>;
  control?: Control<T>;
  transform?: (value: string) => string;
};

export const ControlledDigitsField = <T extends FieldValues>({
  name,
  control,
  transform = (val) => val,
  ...restProps
}: IProps<T>) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { ref, ...field },
        fieldState: { error },
        formState: { isSubmitting },
      }) => (
        <FloatingDigitsField
          {...field}
          {...restProps}
          id={name}
          onChange={(value: string) => {
            field.onChange(transform(value));
          }}
          disabled={isSubmitting}
          error={error?.message ? t(error.message) : undefined}
        />
      )}
    />
  );
};

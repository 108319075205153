import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import authContext from 'contexts/authentication/AuthContext';
import { usePageViewTracking } from 'hooks/usePageViewTracking';
import { AnalyticsService } from 'integrations/analytics/analytics.service';
import {
  IMFA_REQUIRED_STATUS,
  ISignInResponse,
} from 'integrations/menhir/employee/sessions/sessions.types';
import { OTPForm } from './components/OTPForm/OTPForm';
import { SignInForm } from './components/SignInForm/SignInForm';

export const SignIn = () => {
  usePageViewTracking('login_page');
  const history = useHistory();
  const { signIn: userSignIn, initialRoute } = useContext(authContext);
  const [MFAStatus, setMFAStatus] = useState<IMFA_REQUIRED_STATUS>();

  const onSubmit = async (signInResponse: ISignInResponse) => {
    /* further authentication factors required */
    if ('reason' in signInResponse) {
      if (signInResponse.reason === 'OTP_REQUIRED') {
        AnalyticsService.trackCustomEvent('login_otp_required');
        setMFAStatus(signInResponse);
      }
    }

    /* success */
    if ('id' in signInResponse) {
      const user = signInResponse;
      userSignIn(user);
      AnalyticsService.trackCustomEvent('login_success', {
        employee_id: user.id,
        employee_email: user.email,
        company_id: user.companyId,
      });
      try {
        if (initialRoute) {
          history.push(initialRoute);
          return;
        }
        history.push(ROUTES.ROOT, { origin: 'login' });
      } catch {
        history.push(ROUTES.PROFILE);
      }
    }
  };

  if (MFAStatus?.reason === 'OTP_REQUIRED') {
    return <OTPForm onSubmit={onSubmit} otpRequiredStatus={MFAStatus} />;
  }

  return <SignInForm onSubmit={onSubmit} />;
};

import { ICompany } from 'types/company';
import { IMenhirCompany } from 'types/menhir/company';

export const transformCompany = (menhirCompany: IMenhirCompany): ICompany => ({
  id: menhirCompany.id,
  name: menhirCompany.name,
  legalName: menhirCompany.legal_name,
  logoUrl: menhirCompany.logo_uri ?? '',
  taxId: menhirCompany.tax_id,
  addresses: menhirCompany.addresses.map((address) => ({
    addressLine1: address.address_line_1,
    addressLine2: address.address_line_2,
    zipCode: address.zipcode,
    city: address.city,
    country: address.country,
    type: address.type,
  })),
  documents: menhirCompany.documents ?? [],
  settings: {
    cardShippingStrategy: menhirCompany.settings.card_shipping_strategy,
    plan: menhirCompany.settings.plan,
    savingsEnabled: menhirCompany.settings.savings_employee_enabled,
    budgetsEnabled: menhirCompany.settings.budgets_enabled,
    kinshipDegreeProofRequired:
      menhirCompany.settings.kinship_degree_proof_required,
  },
  market: {
    languages: menhirCompany.market.languages,
    slug: menhirCompany.market.slug,
  },
  cardDisplayName: menhirCompany.card_display_name,
});

export const CompanyDTO = {
  transformCompany,
};

import { BulletsLoading } from '@coverflex-tech/hypernova';
import css from './LoadingPlaceholder.module.css';

export const LoadingPlaceholder = () => {
  return (
    <div data-test-id="loading" className={css.host}>
      <BulletsLoading />
    </div>
  );
};

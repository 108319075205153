import { TObjectLeaves } from 'types/general';

export const MENHIR_ENDPOINTS = {
  EMPLOYEE: {
    DETAIL: {
      ROOT: '/employee/:userId',
      REVIEW: '/employee/:userId/review',
      PROFILE: {
        PERSONAL_INFORMATION: '/employee/:userId/profile/personal-information',
        ADDRESS_INFORMATION: '/employee/:userId/profile/address-information',
      },
    },
    FAMILY: {
      ROOT: '/employee/family',
      DETAIL: '/employee/family/:id',
    },
    IDENTIFICATIONS: {
      ROOT: '/employee/identifications',
      DETAIL: '/employee/identifications/:documentType',
    },
    TAX_PROFILE: '/employee/tax-profile',
    ACTIONS: '/employee/actions',
    COMPANY: '/employee/company',
    BENEFITS: {
      ROOT: '/employee/benefits',
      DETAIL: '/employee/benefits/:slug',
      PRODUCTS: '/employee/benefits/:slug/products',
      OPTIN: '/employee/benefits/opt-in',
    },
    BUDGETS: {
      ROOT: '/employee/budgets',
      DETAIL: '/employee/budgets/:budgetId',
      FAMILY_MEMBER: '/employee/budgets/:budgetEmployeeId/member',
      BUDGET_EMPLOYEES: {
        ROOT: '/employee/budgets/:budgetId/budget_employees',
        EXPENSES: {
          DETAIL:
            '/employee/budgets/:budgetId/budget_employees/:budgetEmployeeId/expenses/:expenseId',
        },
      },
      APPLICATIONS: {
        ROOT: '/employee/budgets/applications',
        FILES: '/employee/budgets/applications/files',
      },
      BUDGET_APPLICATIONS: {
        DETAIL: '/employee/budgets/:budgetId/applications/:applicationId',
        APPLY: '/employee/budgets/:budgetId/applications',
        CANCEL:
          '/employee/budgets/:budgetId/applications/:applicationId/cancel',
        TEMPLATE: '/employee/budgets/:budgetId/application_template',
      },
      VOUCHERS: {
        CHILDCARE: '/employee/budgets/:budgetEmployeeId/vouchers/childcare',
      },
    },
    CARD: {
      ROOT: '/employee/card',
      LOCK: '/employee/card/lock',
      UNLOCK: '/employee/card/unlock',
      ACTIVATE: '/employee/card/activate',
      REQUEST_PIN_SMS: '/employee/card/request-pin-sms',
      PIN: {
        ONE_TIME_TOKEN: '/employee/card/pin/one-time-token',
        SET: '/employee/card/pin/set',
      },
    },
    ESTABLISHMENTS: {
      ROOT: '/employee/establishments',
      DETAIL: '/employee/establishments/:id',
      REQUEST: '/employee/establishments/request',
    },
    FEATURES: '/employee/features',
    INSURANCES: {
      ROOT: '/employee/insurances',
      POLICY: '/employee/insurances/:id',
      COVERED_MEMBER: '/employee/insurances/:id/:familyMemberId',
      NOT_COVERED_MEMBERS:
        '/employee/insurances/:id/family_members_not_included',
      DUPLICATED_FAMILY_MEMBERS:
        '/employee/insurances/duplicated-family-members',
      UPGRADE_OPTIONS: '/employee/insurances/upgrade-options',
      PAYROLL_PAYMENTS: '/employee/insurances/:id/payroll-payments',
      REMOVE_FROM_POLICY: '/employee/insurances/opt-out',
    },
    INVESTMENTS: {
      RETIREMENT_POLICY: {
        DETAIL: '/employee/investments/retirement-policy/:policyId',
      },
    },
    MARKETPLACE: {
      APPLE: {
        ORDERS: {
          DETAIL: '/employee/marketplace/apple/orders/:orderId',
          CANCEL: '/employee/marketplace/apple/orders/:orderId/cancel',
        },
        STORES: {
          APPLE: {
            ORDERS: '/employee/marketplace/apple/stores/apple/orders',
            PRODUCTS: {
              DETAIL:
                '/employee/marketplace/apple/stores/apple/products/:encodedReference',
            },
          },
        },
      },
    },
    NETWORKS: {
      REQUEST: '/employee/networks/establishments/request',
    },
    OPERATIONS: {
      ROOT: '/employee/operations',
      DETAIL: '/employee/operations/:id',
      PERIODS: '/employee/operations/periods',
    },
    FLEXIBLE_BENEFITS: {
      SUMMARY: '/employee/flexible-benefits/summary',
      PAYROLL: {
        SUMMARY: '/employee/flexible-benefits/payroll/summary',
      },
      BENEFITS: {
        SUMMARY: '/employee/flexible-benefits/benefits/:slug/summary',
        ROLLOVER: '/employee/flexible-benefits/benefits/:slug/rollover',
      },
      ROLLOVER: '/employee/flexible-benefits/rollover',
    },
    MOVEMENTS: '/employee/movements',
    ORDERS: {
      ROOT: '/employee/orders/:category',
      CASHOUTS: '/employee/orders/cashouts',
      DETAIL: '/employee/orders/:category/:id',
      DONATIONS: {
        ROOT: '/employee/orders/donations',
        DETAIL: '/employee/orders/donations/:id',
      },
      REIMBURSEMENTS: {
        DETAIL: '/employee/orders/reimbursements/:category/:id',
        CANCEL: '/employee/orders/reimbursements/:category/:id/cancel',
        FILES: '/employee/orders/reimbursements/:category/files',
      },
      TOP_UP: '/employee/orders/:category/topup',
    },
    PENSION_FUNDS: {
      ROOT: '/employee/pension-funds',
      CONTRIBUTION: '/employee/pension-funds/contribution',
    },
    POCKETS: '/employee/pockets',
    PAYMENTS: {
      METHODS: {
        ROOT: '/employee/payments/methods',
        SETUP: '/employee/payments/methods/setup',
        PAYMENT_REQUEST:
          '/employee/payments/methods/:paymentMethodId/payment-request',
      },
    },
    SPOTLIGHTS: '/employee/spotlights',
    SALARY: {
      EWA: {
        DETAILS: '/employee/salary/ewa',
        REQUEST: '/employee/salary/ewa/request',
        REQUESTS: '/employee/salary/ewa/requests',
        SCHEDULE: '/employee/salary/ewa/scheduling',
        SCHEDULE_VALIDATE: '/employee/salary/ewa/scheduling/validate',
        SCHEDULE_CONFIRM: '/employee/salary/ewa/scheduling/confirm',
        CALENDAR: '/employee/salary/ewa/calendar',
      },
    },
    VOUCHERS: {
      ROOT: '/employee/vouchers/:type',
      DETAIL: '/employee/vouchers/:type/:id',
      BALANCE: '/employee/vouchers/balance',
    },
    WELFARE: {
      BALANCE: '/employee/welfare/balance',
    },
    REIMBURSEMENTS: {
      REIMBURSABLE_ITEMS: '/employee/reimbursements/reimbursable-items',
      BENEFICIARIES: '/employee/reimbursements/:category/beneficiaries',
    },
    SAVINGS: '/employee/savings',
    SESSIONS: {
      ROOT: '/employee/sessions',
      RENEW: '/employee/sessions/renew',
      SIGNUP: '/employee/registration',
      RESET: '/users/password_reset',
      TRUST_USER_AGENT: '/employee/sessions/trust-user-agent',
      SSO: {
        AUTHENTICATE: '/employee/sessions/sso/authenticate',
        AUTHORIZE: '/employee/sessions/sso/authorize',
      },
    },
  },
  CATALOG: {
    PRODUCTS: {
      CALCULATE_FEES: '/catalog/products/:productId/calculate-fees',
    },
  },
  EXTERNAL_INTEGRATIONS: '/external-integrations',
  FORMS: '/forms/:id',
} as const;

export type TMenhirEndpoint = TObjectLeaves<typeof MENHIR_ENDPOINTS>;

export type TEndpointParamsArray<R extends string> =
  R extends `${string}:${infer P2}`
    ? [string, ...TEndpointParamsArray<P2>]
    : [];

export const endpointFor = <R extends string = TMenhirEndpoint>(
  route: R,
  ...args: TEndpointParamsArray<R>
) => {
  return args.reduce<string>((r, arg) => r.replace(/:\w+/, arg), route);
};

export const apiUrl = (path: string) => `/api${path}`;

import {
  format as dateFnsFormat,
  isAfter,
  isValid,
  parse,
  parseISO,
  startOfToday,
} from 'date-fns';
import { i18n } from 'i18n';
import { LanguageType } from 'types/general';

export const DMY_REGEX =
  /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/(\d{4})$/;

export const now = () => new Date();

export const parseDate = (date?: string | null) => {
  let dt = typeof date === 'string' ? date : '';
  dt = dt.trim().replace(/\s/gi, 'T');

  if (DMY_REGEX.test(dt)) {
    dt = dt.replace(DMY_REGEX, '$3-$2-$1');
  }

  return parseISO(dt);
};

export const safeParseDate = <F = undefined>(
  date?: string | null,
  fallback?: F
) => {
  try {
    return sanitizeDate(date);
  } catch (error) {
    return fallback;
  }
};

export const sanitizeDate = (date?: Date | string | null): Date => {
  if (!date) {
    throw new Error('Invalid date');
  }
  const dt = date instanceof Date ? date : parseDate(date);
  if (isNaN(dt.getTime())) {
    throw new Error('Invalid date');
  }

  return dt;
};

export const DATE_FORMAT = 'dd/MM/yyyy';
export const DATE_ISO_FORMAT = 'yyyy-MM-dd';
export const DATETIME_ISO_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const TIME_ISO_FORMAT = 'HH:mm:ss';

export const formatDate = (
  date?: Date | string | null,
  {
    format,
    fallback = '',
  }: {
    format?: string;
    fallback?: string;
  } = {}
) => {
  try {
    return internalFormat({ date: sanitizeDate(date), format });
  } catch (error) {
    return fallback;
  }
};

export const formatDateISO = (
  date?: Date | string | null,
  {
    fallback,
  }: {
    fallback?: string;
  } = {}
) => {
  return formatDate(date, { format: DATE_ISO_FORMAT, fallback });
};

export const formatTime = (
  date?: Date | string | null,
  {
    fallback = '',
  }: {
    fallback?: string;
  } = {}
) => {
  return formatDate(date, {
    format: TIME_ISO_FORMAT,
    fallback,
  });
};

const internalFormat = ({
  date = now(),
  format = DATE_FORMAT,
}: {
  date?: Date;
  format?: string;
} = {}) => {
  return dateFnsFormat(date, format);
};

export const intlFormat = (
  date?: Date | string | null,
  format?: Intl.DateTimeFormatOptions,
  {
    language = i18n.language as LanguageType,
    fallback = '',
  }: {
    language?: LanguageType;
    fallback?: string;
  } = {}
) => {
  try {
    return new Intl.DateTimeFormat(language, format).format(sanitizeDate(date));
  } catch (error) {
    return fallback;
  }
};

export const INTL_MONTH_DAY_FORMAT = {
  month: 'long',
  day: 'numeric',
  timeStyle: undefined,
} satisfies Intl.DateTimeFormatOptions;
export const INTL_MONTH_YEAR_FORMAT = {
  year: 'numeric',
  month: 'long',
} satisfies Intl.DateTimeFormatOptions;
export const INTL_MONTH_YEAR_2DIGIT_FORMAT = {
  year: '2-digit',
  month: '2-digit',
} satisfies Intl.DateTimeFormatOptions;
export const INTL_DAY_MONTH_2DIGIT_FORMAT = {
  month: '2-digit',
  day: '2-digit',
} satisfies Intl.DateTimeFormatOptions;
export const INTL_MONTH_SHORT_FORMAT = {
  month: 'short',
} satisfies Intl.DateTimeFormatOptions;
export const INTL_MONTH_LONG_FORMAT = {
  month: 'long',
} satisfies Intl.DateTimeFormatOptions;
export const INTL_MONTH_DAY_SHORT_FORMAT = {
  month: 'short',
  day: 'numeric',
} satisfies Intl.DateTimeFormatOptions;

export const getYearsDifferenceFromToday = (dateString: string) => {
  const date = parseDate(dateString);
  const todayDate = now();
  const differenceInYears = todayDate.getFullYear() - date.getFullYear();
  if (
    todayDate.getMonth() < date.getMonth() ||
    (todayDate.getMonth() === date.getMonth() &&
      todayDate.getDate() < date.getDate())
  ) {
    return differenceInYears - 1;
  }
  return differenceInYears;
};

export const formatDateInput = (value: string, keyPressed?: string) => {
  if (keyPressed === 'Backspace') {
    return value;
  }

  const numerics = value.match(/\d/g);
  if (numerics) {
    const numericString = numerics.join('');
    const groups = numericString.match(/(\d{1,2})?(\d{1,2})?(\d{1,4})?/);

    if (groups) {
      let [, day, month = '', year = ''] = groups;
      const dayNumber = Number(day);
      if (dayNumber >= 4 && dayNumber < 10) {
        day = `0${dayNumber}`;
      }

      if (day.length === 2) {
        day = `${day}/`;
      }

      if (month && month.length === 2) {
        month = `${month}/`;
      }

      return `${day}${month}${year}`;
    }
  }

  return '';
};

export const isValidDate = (dateString: string) => {
  const parsedDate = parse(dateString, DATE_FORMAT, now());
  return isValid(parsedDate);
};

export const isTodayAfter = (dateString: string) => {
  const providedDate = parseDate(dateString);
  return isAfter(startOfToday(), providedDate);
};

export const DateUtils = {
  getYearsDifferenceFromToday,
  intlFormat,
  formatTime,
  formatDate,
  formatDateISO,
  formatDateInput,
  parseDate,
  safeParseDate,
  isValid,
  isTodayAfter,
  now,
};

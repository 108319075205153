import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { AuthService } from 'integrations/menhir/employee/sessions/sessions.service';

export const PublicRoute = (routeProps: RouteProps) => {
  const { state } = useLocation();
  const isAuthenticated = AuthService.checkIfAuthenticated();

  if (isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: ROUTES.ROOT,
          state,
        }}
      />
    );
  }
  return <Route {...routeProps} />;
};

export default PublicRoute;

import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Alert, Button, Heading, Text } from '@coverflex-tech/hypernova';
import { zodResolver } from '@hookform/resolvers/zod';
import { ROUTES } from 'constants/routes';
import { usePageViewTracking } from 'hooks/usePageViewTracking';
import { AnalyticsService } from 'integrations/analytics/analytics.service';
import { AuthService } from 'integrations/menhir/employee/sessions/sessions.service';
import { ISignInResponse } from 'integrations/menhir/employee/sessions/sessions.types';
import { AuthenticationLayout } from 'layouts/AuthenticationLayout/AuthenticationLayout';
import errorUtils from 'utils/error';
import { ControlledPasswordField } from 'components/Fields/ControlledPasswordField/ControlledPasswordField';
import { ControlledTextField } from 'components/Fields/ControlledTextField/ControlledTextField';
import { ControllerContext } from 'components/Fields/ControllerContext/ControllerContext';
import SessionExpiredModal from '../SessionExpiredModal';
import { ILoginFormData, formSchema } from './schema';
import { css } from './SignInForm.css';

interface ILocationState {
  origin?: 'signup';
  sessionExpired?: boolean;
}

interface IFeedback {
  type: 'error' | 'success';
  message: string;
}

export const SignInForm = ({ onSubmit }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<ILocationState>();
  usePageViewTracking('login_page');
  const [displaySessionExpiredModal, toggleSessionExpiredModal] =
    useState(false);

  const [feedback, setFeedback] = useState<IFeedback | null>(
    location?.state?.origin
      ? {
          type: 'success',
          message: t(`authentication.signin.feedback.${location.state.origin}`),
        }
      : null
  );

  useEffect(() => {
    if (location?.state?.sessionExpired) {
      AnalyticsService.trackCustomEvent('login_session_expired');
      toggleSessionExpiredModal(true);
      history.replace(ROUTES.SIGNIN, {});
    }
  }, [location, history]);

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(formSchema),
  });

  const onFormSubmit = async (form: ILoginFormData) => {
    const { email, password } = form;

    setFeedback(null);
    try {
      const signInResponse = await AuthService.signIn({ email, password });
      onSubmit(signInResponse);
    } catch (err: any) {
      const errorMessage = errorUtils.getErrorMessageKey(err?.errorCode);
      setFeedback({
        type: 'error',
        message: t(errorMessage),
      });
      AnalyticsService.trackCustomEvent('login_failed');
    }
  };

  return (
    <AuthenticationLayout>
      <div className={css.host}>
        {displaySessionExpiredModal && (
          <SessionExpiredModal
            onClose={() => toggleSessionExpiredModal(false)}
          />
        )}
        <Heading level={3}>{t('authentication.signin.form.title')}</Heading>
        <FormProvider {...methods}>
          <form
            className={css.form}
            onSubmit={methods.handleSubmit(onFormSubmit)}
          >
            <ControlledTextField
              name="email"
              type="email"
              label={t('authentication.signin.form.email.label')}
            />
            <ControlledPasswordField
              name="password"
              label={t('authentication.signin.form.password.label')}
            />
            <ControllerContext
              render={({ formState: { isSubmitting } }) => (
                <>
                  {!isSubmitting && feedback && (
                    <Alert type={feedback.type}>{feedback.message}</Alert>
                  )}
                  <div className={css.actions}>
                    <Button type="submit" loading={isSubmitting}>
                      {t('authentication.signin.form.button')}
                    </Button>
                    <Link to={ROUTES.RESET_PASSWORD}>
                      <Button variant="tertiary" presentational>
                        {t('authentication.signin.forgot_password')}
                      </Button>
                    </Link>
                  </div>
                </>
              )}
            />
          </form>
        </FormProvider>
        <div className={css.links}>
          <Link to={ROUTES.SSO_SIGNIN}>
            <Text underline bold size="sm">
              {t('authentication.signin.sso_link')}
            </Text>
          </Link>
          {import.meta.env.VITE_APOLLO_URL && (
            <a href={`${import.meta.env.VITE_APOLLO_URL}`}>
              <Text underline bold size="sm">
                {t('authentication.signin.company_link')}
              </Text>
            </a>
          )}
        </div>
      </div>
    </AuthenticationLayout>
  );
};

interface IProps {
  onSubmit: (data: ISignInResponse) => void;
}

import { isPasswordValid } from 'utils/password';
import { requiredString } from 'utils/zod';
import { z } from 'zod';

export const formSchema = z
  .object({
    token: z.string(),
    password: requiredString,
    passwordConfirmation: requiredString,
  })
  .superRefine((data, ctx) => {
    if (!isPasswordValid(data.password)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'authentication.create_password.hint',
        path: ['password'],
      });
    }
    if (data.password !== data.passwordConfirmation) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'authentication.create_password.errors.passwords_dont_match',
        path: ['passwordConfirmation'],
      });
    }
  });

export type ICreatePasswordFormData = z.infer<typeof formSchema>;

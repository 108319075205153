import { FormEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FloatingPhoneField } from '@coverflex-tech/hypernova';
import { usePhoneNumberSetup } from 'integrations/menhir/employee/sessions/sessions.hooks';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { safeParsePhoneNumber } from 'utils/phone';
import { notify } from 'utils/toast/toast';
import css from './PhoneForm.module.css';

interface IProps {
  initialValue?: string;
  token: string;
  onConfirm: (number: string) => void;
}

export const PhoneForm = ({ initialValue, token, onConfirm }: IProps) => {
  const { t, i18n } = useTranslation();
  const { mutateAsync: setupPhoneNumber, isLoading } = usePhoneNumberSetup();
  const parsedPhoneObject = initialValue
    ? safeParsePhoneNumber(initialValue)
    : undefined;

  const [phone, setPhone] = useState({
    dialCode: parsedPhoneObject?.countryCallingCode
      ? `+${parsedPhoneObject.countryCallingCode}`
      : '',
    number: parsedPhoneObject?.nationalNumber ?? '',
  });

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    if (!phone.dialCode || !phone.number) return;
    try {
      const fullNumber = `${phone.dialCode}${phone.number}`;
      await setupPhoneNumber({
        invitationCode: token,
        phoneNumber: fullNumber,
      });
      onConfirm(fullNumber);
    } catch {
      notify({
        message: t('authentication.mfa.phone_setup.error'),
        type: 'error',
      });
    }
  };

  const error = useMemo(() => {
    if (!phone.dialCode || !phone.number) {
      return t('errors.input.required');
    }

    const isValidNumber = isValidPhoneNumber(
      `${phone.dialCode}${phone.number}`
    );

    if (!isValidNumber) {
      return t('errors.input.invalid_phone');
    }
  }, [phone, t]);

  return (
    <form className={css.form} onSubmit={onSubmit}>
      <FloatingPhoneField
        ids={{ codeField: 'dialCode', numberField: 'number' }}
        labels={{
          codeField: t('form.fields.dial_code'),
          numberField: t('form.fields.phone_number'),
          noResults: t('form.search.no_results'),
        }}
        value={phone}
        errors={{ numberField: error }}
        onChange={(id, value) => {
          setPhone((prevPhone) => ({
            ...prevPhone,
            [id]: value,
          }));
        }}
        language={i18n.language}
      />
      <Button
        type="submit"
        className={css.button}
        disabled={!!error}
        loading={isLoading}
      >
        {t('authentication.mfa.send_code')}
      </Button>
    </form>
  );
};

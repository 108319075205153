import { ReactNode } from 'react';
import { FieldValues, UseFormReturn, useFormContext } from 'react-hook-form';

export const ControllerContext = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
>({
  render,
}: {
  render: (arg: UseFormReturn<TFieldValues, TContext>) => ReactNode;
}) => {
  const formContext = useFormContext<TFieldValues>();

  return <>{render(formContext)}</>;
};

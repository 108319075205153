import {
  ICitizenCard,
  IEmployeeIdentification,
  ITransportationCard,
} from 'integrations/menhir/employee/employee/employee.types';
import { IIdentificationSlug, IIdentificationType } from 'types/general';

export const isCitizenCard = (
  identification: IEmployeeIdentification<IIdentificationSlug>
): identification is ICitizenCard => identification.type === 'citizen_card';

export const isTransportationCard = (
  identification: IEmployeeIdentification<IIdentificationSlug>
): identification is ITransportationCard =>
  identification.type === 'transportation_card';

export const IdentificationDocumentSlugMapping: Record<
  IIdentificationType,
  IIdentificationSlug
> = {
  citizen_card: 'citizen-card',
  transportation_card: 'transportation-card',
};

export const DocumentSlugIdentificationTypeMapping: Record<
  IIdentificationSlug,
  IIdentificationType
> = {
  'citizen-card': 'citizen_card',
  'transportation-card': 'transportation_card',
};

import { TMoney } from '@coverflex-tech/hypernova-utils';
import { isAfter } from 'date-fns';
import { t } from 'i18next';
import { z } from 'zod';
import { DMY_REGEX, isValidDate, now, parseDate } from './date';

export const requiredString = z.string().min(1, t('errors.input.required'));

export const requiredBoolean = z.boolean().refine((value) => value, {
  message: 'errors.input.required',
});

export const fileSchema = z.object(
  {
    id: z.string().optional(),
    name: requiredString,
    url: z.string().optional(),
    slug: z.string().optional(),
    blob: z.instanceof(File),
  },
  {
    invalid_type_error: 'errors.input.required',
    required_error: 'errors.input.required',
  }
);

export const requiredMoneySchema = z
  .object({
    amount: z.number(),
    currency: z.string(),
  })
  .nullable()
  .refine(
    (value): value is TMoney =>
      !!value && value.amount !== null && value.amount !== undefined,
    {
      message: 'errors.input.required',
    }
  );

export const nonNegativeMoneySchema = requiredMoneySchema.refine(
  (value) => value && value.amount >= 0,
  {
    message: 'errors.input.not_negative',
  }
);

export const minRequiredMoneySchema = requiredMoneySchema.refine(
  (value) => value && value.amount > 0,
  {
    message: 'errors.input.should_be_greater_than',
  }
);

export const birthdateValidator = (val: string) => {
  if (!isValidDate(val) || !DMY_REGEX.test(val)) {
    return false;
  }
  const parsedDate = parseDate(val);
  return !isAfter(parsedDate, now());
};

export const addressSchema = z.object({
  zipCode: z.string(),
  type: z.enum(['mailing', 'card_shipping_address', 'legal']),
  country: z.string(),
  city: z.string(),
  addressLine1: z.string(),
  addressLine2: z.string().optional().nullable(),
  district: z.string().optional(),
});

export const amountSchema = z.object(
  {
    amount: z.number(),
    currency: z.string(),
  },
  {
    required_error: 'errors.input.required',
    invalid_type_error: 'errors.input.required',
  }
);

export const dynamicFormSchema = z
  .object({
    required: z
      .object({
        text: z.record(z.string(), requiredString).optional(),
        date: z
          .record(
            z.string(),
            z.date({
              invalid_type_error: 'errors.input.required',
              required_error: 'errors.input.required',
            })
          )
          .optional(),
        checkbox: z.record(z.string(), z.literal(true)).optional(),
        select: z.record(z.string(), requiredString).optional(),
      })
      .optional(),
    optional: z
      .object({
        text: z.record(z.string(), z.string().optional()).optional(),
        date: z
          .record(
            z.string(),
            z
              .date({
                invalid_type_error: 'errors.input.required',
                required_error: 'errors.input.required',
              })
              .optional()
          )
          .optional(),
        checkbox: z.record(z.string(), z.boolean().optional()).optional(),
        select: z.record(z.string(), z.string().optional()).optional(),
      })
      .optional(),
  })
  .optional();

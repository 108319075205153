import { IPasswordStrength, PasswordValidatorType } from 'types/general';

const LENGTH_REGEX = /^.{8,80}$/;
const UPPERCASE_REGEX = /[A-Z]/;
const LOWERCASE_REGEX = /[a-z]/;
const SPECIAL_CHAR_REGEX = /[!?@#$%^&*_0-9]/;

const validators: { key: PasswordValidatorType; regex: RegExp }[] = [
  {
    key: 'length',
    regex: LENGTH_REGEX,
  },
  {
    key: 'lowercase',
    regex: LOWERCASE_REGEX,
  },
  {
    key: 'uppercase',
    regex: UPPERCASE_REGEX,
  },
  {
    key: 'special_char',
    regex: SPECIAL_CHAR_REGEX,
  },
];

export const isPasswordValid = (password: string) => {
  return validators.every(({ regex }) => regex.test(password));
};

export const getPasswordValidation = (password: string) => {
  const validRegexes = validators
    .filter(({ regex }) => regex.test(password))
    .map(({ key }) => key);

  let score: IPasswordStrength = 'weak';
  if (validRegexes.length < 2) {
    score = 'weak';
  } else if (validRegexes.length < 3) {
    score = 'medium';
  } else {
    score = 'strong';
  }

  return {
    isValid: validRegexes.length === validators.length,
    score,
    validRegexes,
  };
};

import { REGEX_EMAIL } from 'constants/regex';
import { z } from 'zod';

export const formSchema = z.object({
  email: z
    .string()
    .regex(REGEX_EMAIL, 'errors.input.invalid_email')
    .nonempty('errors.input.required'),
});

export type IResetPasswordFormData = z.infer<typeof formSchema>;

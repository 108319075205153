import { ReactNode } from 'react';
import classNames from 'classnames';
import css from './title.module.css';

interface IProps {
  size?: 'large' | 'medium' | 'small';
  className?: string;
  children: ReactNode;
}

const Title = ({ size = 'medium', className, children }: IProps) => {
  return (
    <div className={classNames(css.host, css[size], className)}>{children}</div>
  );
};

export default Title;

import { CardStatus, ICard, ICardTokens } from './card.types';
import {
  IMenhirCardEnvelope,
  IMenhirCardStatus,
  IMenhirCardTokens,
  MenhirCardStatusType,
} from './card.types';

const normalizeStatus = (status: MenhirCardStatusType): CardStatus => {
  if (status === 'pending') return 'inactive';
  return status;
};

export const transformCard = (menhirCard: IMenhirCardEnvelope): ICard => ({
  id: menhirCard.card.id,
  status: normalizeStatus(menhirCard.card.status),
  providerId: menhirCard.card.provider_id,
  providerData: menhirCard.card.provider_data
    ? {
        numMP: menhirCard.card.provider_data?.numMP,
        firma: menhirCard.card.provider_data?.firma,
      }
    : undefined,
  panLastDigits: menhirCard.card.pan_last_digits,
  ownerId: menhirCard.card.owner_id,
  holderName: menhirCard.card.holder_name,
  expirationDate: menhirCard.card.expiration_date,
  shippingAddress: menhirCard.card.shipping_address
    ? {
        addressLine1: menhirCard.card.shipping_address.address_line_1,
        addressLine2: menhirCard.card.shipping_address.address_line_2,
        zipCode: menhirCard.card.shipping_address.zipcode,
        city: menhirCard.card.shipping_address.city,
        country: menhirCard.card.shipping_address.country,
        district: menhirCard.card.shipping_address.district,
        type: menhirCard.card.shipping_address.type,
      }
    : undefined,
  holderCompanyName: menhirCard.card.holder_company_name,
  network: menhirCard.card.network,
  replacedBy: menhirCard.card.replaced_by
    ? {
        holderName: menhirCard.card.replaced_by.holder_name,
        holderCompanyName: menhirCard.card.replaced_by.holder_company_name,
        expirationDate: menhirCard.card.replaced_by.expiration_date,
      }
    : undefined,
});

export const transformStatus = (
  menhirCardStatus: IMenhirCardStatus
): CardStatus => normalizeStatus(menhirCardStatus.status);

export const transformCardTokens = (
  menhirCardTokens: IMenhirCardTokens
): ICardTokens => ({
  publicKey: menhirCardTokens.public_key,
  oneTimeToken: menhirCardTokens.token,
});

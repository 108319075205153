import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@coverflex-tech/hypernova';
import {
  IPhoneRequiredStatus,
  ISignInResponse,
} from 'integrations/menhir/employee/sessions/sessions.types';
import { MFALayout } from 'layouts/MFALayout/MFALayout';
import { CodeForm } from './components/CodeForm/CodeForm';
import { PhoneForm } from './components/PhoneForm/PhoneForm';
import css from './MFAFlow.module.css';

enum STEPS {
  PHONE,
  CODE,
}

interface IProps {
  token: string;
  status: IPhoneRequiredStatus;
  onSubmit: (response: ISignInResponse) => void;
}

export const MFAFlow = ({ token, status, onSubmit }: IProps) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(STEPS.PHONE);
  const [phoneNumber, setPhoneNumber] = useState(status.phoneNumber ?? '');

  const onConfirmPhone = (number: string) => {
    setPhoneNumber(number);
    setCurrentStep(STEPS.CODE);
  };

  return (
    <MFALayout>
      <div className={css.content}>
        <div className={css.header}>
          <Heading level={4}>
            {t(
              currentStep === STEPS.PHONE
                ? 'authentication.mfa.phone_setup.title'
                : 'authentication.signin.form.mfa.otp.title',
              {
                suffix: phoneNumber.slice(phoneNumber.length - 4),
              }
            )}
          </Heading>
          <Text className={css.steps}>
            {t('authentication.mfa.steps', {
              current: currentStep + 1,
              total: 2,
            })}
          </Text>
        </div>
        <div className={css.form}>
          {currentStep === STEPS.PHONE && (
            <PhoneForm
              initialValue={phoneNumber}
              token={token}
              onConfirm={onConfirmPhone}
            />
          )}
          {currentStep === STEPS.CODE && (
            <CodeForm
              token={token}
              phoneNumber={phoneNumber}
              onBack={() => setCurrentStep(STEPS.PHONE)}
              onConfirm={onSubmit}
            />
          )}
        </div>
        {currentStep === STEPS.CODE && (
          <Text className={css.help} size="sm">
            {t('authentication.mfa.help_message')}
          </Text>
        )}
      </div>
    </MFALayout>
  );
};

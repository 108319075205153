import { TMarket } from 'constants/markets';
import { IEmployee } from 'integrations/menhir/employee/employee/employee.types';
import { getExternalIntegrations } from 'integrations/menhir/external-integrations/external-integrations.service';
import { ICompany } from 'types/company';
import {
  ITrackingCustomEventTraits,
  ITrackingEventType,
  ITrackingPageViewTraits,
  ITrackingPageViewType,
} from 'types/tracking';

declare global {
  interface Window {
    analytics: any;
    zE: any;
  }
}

const trackIdentification = (employee: IEmployee, company: ICompany) => {
  try {
    window.analytics.identify(employee.id, {
      email: employee.email,
    });
    window.analytics.group(employee.companyId);
  } catch {
  } finally {
    authenticateChatUser(company.market.slug);
  }
};

const trackCustomEvent = <T extends ITrackingEventType>(
  type: T,
  metadata?: ITrackingCustomEventTraits[T]
) => {
  try {
    window.analytics.track(type, metadata);
  } catch {
    return null;
  }
};

const trackPageView = <T extends ITrackingPageViewType = ITrackingPageViewType>(
  pageName: T,
  metadata?: ITrackingPageViewTraits[T]
) => {
  try {
    window.analytics.page(pageName, metadata);
  } catch {
    return null;
  }
};

const authenticateChatUser = async (market: TMarket) => {
  if (window.zE) {
    try {
      const integrations = await getExternalIntegrations();
      if (integrations.zendesk.liveChatToken) {
        window.zE(
          'messenger',
          'loginUser',
          (callback: (token: string) => void) => {
            callback(integrations.zendesk.liveChatToken);
          }
        );
        window.zE('messenger:set', 'zIndex', 9);
        setChatConversationFields(market);
      }
    } catch {}
  }
};

const logoutChatUser = () => {
  if (window.zE) {
    try {
      window.zE('messenger', 'logoutUser');
    } catch {}
  }
};

const openChat = () => {
  if (window.zE) {
    try {
      window.zE('messenger', 'open');
    } catch {}
  }
};

const setChatConversationFields = (market: TMarket) => {
  if (window.zE) {
    window.zE('messenger:set', 'conversationFields', [
      {
        id: '19164336063261',
        value: `market_${market}`,
      },
      {
        id: '19164643640221',
        value: 'employee',
      },
    ]);
  }
};

export const AnalyticsService = {
  trackCustomEvent,
  trackPageView,
  trackIdentification,
  logoutChatUser,
  openChat,
  setChatConversationFields,
};

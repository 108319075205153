import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Alert, Button, Heading, Text } from '@coverflex-tech/hypernova';
import { zodResolver } from '@hookform/resolvers/zod';
import { ROUTES } from 'constants/routes';
import AuthContext from 'contexts/authentication/AuthContext';
import { LocationDescriptor } from 'history';
import {
  SSOAuthenticate,
  SSOAuthorize,
} from 'integrations/menhir/employee/sessions/sessions.service';
import { IUser } from 'integrations/menhir/employee/sessions/sessions.types';
import { AuthenticationLayout } from 'layouts/AuthenticationLayout/AuthenticationLayout';
import { parse } from 'query-string';
import { ControlledTextField } from 'components/Fields/ControlledTextField/ControlledTextField';
import { ControllerContext } from 'components/Fields/ControllerContext/ControllerContext';
import { LoadingPlaceholder } from 'components/LoadingPlaceholder/LoadingPlaceholder';
import { IOAuthLoginFormData, formSchema } from './schema';
import { css } from './OAuthLogin.css';

export const OAuthLogin = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { code, state, error } = parse(location.search);
  const { signIn, initialRoute } = useContext(AuthContext);
  const requestedRef = useRef(false);
  const history = useHistory();

  const [authorizing, setAuthorizing] = useState(!!code && !!state);
  const [invalidAccount, setInvalidAccount] = useState(!!error);

  const onSignIn = useCallback(
    (user: IUser, location: LocationDescriptor) => {
      signIn(user);
      history.push(location);
    },
    [history, signIn]
  );

  useEffect(() => {
    (async () => {
      if (code && state && !requestedRef.current) {
        try {
          requestedRef.current = true;
          const user = await SSOAuthorize(code as string, state as string);
          try {
            if (initialRoute) {
              onSignIn(user, initialRoute);
              return;
            }
            onSignIn(user, {
              pathname: ROUTES.ROOT,
              state: { origin: 'login' },
            });
          } catch {
            onSignIn(user, ROUTES.PROFILE);
          }
        } catch {
          setInvalidAccount(true);
          setAuthorizing(false);
        }
      }
    })();
  }, [code, state, onSignIn, initialRoute]);

  const onSubmit = async ({ email }: IOAuthLoginFormData) => {
    try {
      setInvalidAccount(false);
      const { url } = await SSOAuthenticate(email);
      if (url) {
        window.open(url, '_self');
      }
    } catch {
      setInvalidAccount(true);
    }
  };

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(formSchema),
  });

  if (authorizing) {
    return (
      <div className={css.loading}>
        <LoadingPlaceholder />
      </div>
    );
  }

  return (
    <AuthenticationLayout>
      <Heading level={3} className={css.title}>
        {t('authentication.sso.title')}
      </Heading>
      <Text color="neutral30">{t('authentication.sso.description')}</Text>
      <FormProvider {...methods}>
        <form className={css.form} onSubmit={methods.handleSubmit(onSubmit)}>
          <ControlledTextField
            name="email"
            type="email"
            label={t('authentication.sso.fields.email')}
          />
          {invalidAccount && (
            <Alert type="error">
              <Trans i18nKey="authentication.sso.errors.wrong_email" />
            </Alert>
          )}
          <ControllerContext
            render={({ formState: { isSubmitting } }) => (
              <Button
                type="submit"
                loading={isSubmitting}
                className={css.button}
              >
                {t('authentication.sso.confirm_button')}
              </Button>
            )}
          />
        </form>
      </FormProvider>
      <div className={css.links}>
        <Link to={ROUTES.SIGNIN}>
          <Text underline bold size="sm">
            {t('authentication.sso.link')}
          </Text>
        </Link>
        {import.meta.env.VITE_APOLLO_URL && (
          <a href={`${import.meta.env.VITE_APOLLO_URL}`}>
            <Text underline bold size="sm">
              {t('authentication.signin.company_link')}
            </Text>
          </a>
        )}
      </div>
    </AuthenticationLayout>
  );
};

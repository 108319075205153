import { Route, Router, Switch } from 'react-router-dom';
import { browserHistory } from 'browser-history';
import { ROUTES } from 'constants/routes';
import { AuthenticationRoutes } from 'containers/Authentication/AuthenticationRoutes';
import { Dashboard } from 'containers/Dashboard/Dashboard';
import { PageNotFound } from 'containers/PageNotFound/PageNotFound';
import { AppInitProvider } from 'contexts/AppInitProvider';
import { ThemeProvider } from 'contexts/ThemeProvider/ThemeProvider';
import { ToastProvider } from 'contexts/ToastProvider';
import AuthProvider from 'contexts/authentication/AuthProvider';
import CartProvider from 'contexts/cart/CartProvider';
import { ReactQueryProvider } from 'contexts/react-query/ReactQueryProvider';
import { ErrorReportService } from 'integrations/error-report/error-report.service';
import { ScrollToTop } from 'utils/ScrollToTop';
import { safeLazy } from 'utils/import';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { PrivateRoute } from 'components/PrivateRoute/PrivateRoute';
import SuspenseRoute from 'components/SuspenseRoute';

const { Onboarding } = safeLazy(
  () => import('containers/Onboarding/Onboarding')
);
const { ProfileRoutes } = safeLazy(
  () => import('containers/Profile/ProfileRoutes')
);

if (import.meta.env.VITE_ENV && import.meta.env.VITE_ENV !== 'development') {
  ErrorReportService.setupReporter(browserHistory as any);
}

const App = () => (
  <ThemeProvider>
    <ErrorBoundary>
      <ToastProvider>
        <ReactQueryProvider>
          <AuthProvider>
            <CartProvider>
              <Router history={browserHistory}>
                <AppInitProvider>
                  <ScrollToTop />
                  <Switch>
                    <Route
                      path={[
                        ROUTES.SIGNIN,
                        ROUTES.SSO_SIGNIN,
                        ROUTES.SIGNUP,
                        ROUTES.RESET_PASSWORD,
                      ]}
                    >
                      <AuthenticationRoutes />
                    </Route>
                    <Route exact path={ROUTES.NOT_FOUND}>
                      <PageNotFound />
                    </Route>
                    <SuspenseRoute path={ROUTES.ONBOARDING} isPrivate>
                      <Onboarding />
                    </SuspenseRoute>
                    <SuspenseRoute path={ROUTES.PROFILE} isPrivate>
                      <ProfileRoutes />
                    </SuspenseRoute>
                    <PrivateRoute path={ROUTES.ROOT}>
                      <Dashboard />
                    </PrivateRoute>
                  </Switch>
                </AppInitProvider>
              </Router>
            </CartProvider>
          </AuthProvider>
        </ReactQueryProvider>
      </ToastProvider>
    </ErrorBoundary>
  </ThemeProvider>
);

export default App;

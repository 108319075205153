import { ReactNode } from 'react';
import { PhoneAsset } from './components/PhoneAsset/PhoneAsset';
import css from './MFALayout.module.css';

interface IProps {
  children: ReactNode;
}
export const MFALayout = ({ children }: IProps) => {
  return (
    <div className={css.host}>
      <div className={css.leftPanel}>
        <div className={css.leftContent}>{children}</div>
      </div>
      <div className={css.rightPanel}>
        <PhoneAsset />
      </div>
    </div>
  );
};

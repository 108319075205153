import { PhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

export const safeParsePhoneNumber = (number: string): PhoneNumber | null => {
  try {
    return parsePhoneNumber(number);
  } catch {
    return null;
  }
};

export const parsePhone = (value: string) => {
  try {
    const parsedPhone = parsePhoneNumber(value);
    return {
      dialCode: `+${parsedPhone.countryCallingCode}`,
      number: parsedPhone.nationalNumber,
    };
  } catch {
    return null;
  }
};

import { AxiosResponse } from 'axios';
import { AnalyticsService } from 'integrations/analytics/analytics.service';
import { LocalStorageService } from 'integrations/local-storage/local-storage.service';
import { tokenToUser } from 'integrations/menhir/employee/sessions/sessions.dto';
import {
  IAuthResponse,
  ILoginData,
  ISSOAuthenticateResponse,
  ISignInResponse,
  MenhirLoginData,
} from 'integrations/menhir/employee/sessions/sessions.types';
import {
  menhirClient,
  menhirClientVanilla,
} from 'integrations/menhir/menhir.client';
import { MENHIR_ENDPOINTS } from 'integrations/menhir/menhir.endpoints';

const onSuccess = (authResponse: IAuthResponse) => {
  LocalStorageService.updateTokens(
    authResponse.token,
    authResponse.refresh_token,
    authResponse.user_agent_token
  );
  const authData = LocalStorageService.decodeAuthToken(authResponse.token);

  if (!authData) {
    throw new Error('Invalid token data');
  }

  return tokenToUser(authData);
};

const signIn = ({
  email,
  password,
  otp,
  trustUserAgent: trustUserAgentCheck,
}: ILoginData): Promise<ISignInResponse> => {
  return menhirClientVanilla
    .post<any, AxiosResponse<IAuthResponse>, MenhirLoginData>(
      MENHIR_ENDPOINTS.EMPLOYEE.SESSIONS.ROOT,
      {
        email,
        password,
        otp,
        user_agent_token: LocalStorageService.getUserAgentToken() || undefined,
      }
    )
    .then(async ({ status, data }) => {
      if (status === 202) {
        return {
          reason: 'OTP_REQUIRED',
          channel: data.channel,
          phoneLastDigits: data.phone_last_digits,
          email,
          password: password,
        };
      }

      if (trustUserAgentCheck) {
        onSuccess(data);
        return await trustUserAgent();
      }

      return onSuccess(data);
    });
};

const validateInvitation = (token: string) => {
  return menhirClient.post(MENHIR_ENDPOINTS.EMPLOYEE.SESSIONS.SIGNUP, {
    registration: {
      invitation_code: token,
    },
  });
};

const signUp = (
  invitationCode: string,
  password: string
): Promise<ISignInResponse> =>
  menhirClientVanilla
    .post(MENHIR_ENDPOINTS.EMPLOYEE.SESSIONS.SIGNUP, {
      registration: {
        invitation_code: invitationCode,
        password: password,
      },
    })
    .then(({ status, data }) => {
      if (status === 202) {
        return {
          reason: 'PHONE_REQUIRED',
          channel: data.channel,
          phoneNumber: data.phone_number,
        };
      }

      return onSuccess(data);
    });

const setupPhone = (invitationCode: string, phoneNumber: string) =>
  menhirClientVanilla
    .post(MENHIR_ENDPOINTS.EMPLOYEE.SESSIONS.SIGNUP, {
      registration: {
        invitation_code: invitationCode,
        phone_number: phoneNumber,
      },
    })
    .then(({ status, data }) => {
      if (status === 202) {
        return {
          reason: 'OTP_REQUIRED',
          channel: data.channel,
          phoneLastDigits: data.phone_last_digits,
        };
      } else {
        throw new Error();
      }
    });

const confirmSignUp = (invitationCode: string, otp: string) =>
  menhirClientVanilla
    .post(MENHIR_ENDPOINTS.EMPLOYEE.SESSIONS.SIGNUP, {
      registration: {
        invitation_code: invitationCode,
        otp,
      },
    })
    .then(({ data }) => onSuccess(data));

const trustUserAgent = (): Promise<ISignInResponse> => {
  return menhirClient
    .post<undefined, IAuthResponse>(
      MENHIR_ENDPOINTS.EMPLOYEE.SESSIONS.TRUST_USER_AGENT
    )
    .then((data) => {
      return onSuccess(data);
    });
};

const signOut = () => {
  AnalyticsService.logoutChatUser();
  LocalStorageService.clearTokens();
};

const checkIfAuthenticated = () => !!LocalStorageService.getAuthToken();

const requestPasswordReset = (email: string) =>
  menhirClient.post(MENHIR_ENDPOINTS.EMPLOYEE.SESSIONS.RESET, { email });

const passwordReset = (token: string, password: string) =>
  menhirClient.patch(MENHIR_ENDPOINTS.EMPLOYEE.SESSIONS.RESET, {
    token,
    password,
  });

const onSSOSuccess = (user: IAuthResponse) => {
  LocalStorageService.updateTokens(user.token, user.refresh_token);
  const authData = LocalStorageService.decodeAuthToken(user.token);
  if (authData) {
    return tokenToUser(authData);
  } else {
    throw new Error();
  }
};

export const SSOAuthenticate = (email: string) =>
  menhirClient.post<null, ISSOAuthenticateResponse>(
    MENHIR_ENDPOINTS.EMPLOYEE.SESSIONS.SSO.AUTHENTICATE,
    {
      email,
    }
  );

export const SSOAuthorize = (grant: string, state: string) =>
  menhirClient
    .post<null, IAuthResponse>(
      MENHIR_ENDPOINTS.EMPLOYEE.SESSIONS.SSO.AUTHORIZE,
      {
        grant,
        state,
      }
    )
    .then(onSSOSuccess);

export const AuthService = {
  validateInvitation,
  signUp,
  setupPhone,
  confirmSignUp,
  signIn,
  signOut,
  checkIfAuthenticated,
  requestPasswordReset,
  passwordReset,
  trustUserAgent,
  SSOAuthenticate,
  SSOAuthorize,
};

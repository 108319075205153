import { createContext } from 'react';
import { TMoney } from '@coverflex-tech/hypernova-utils';
import { IAppleOrderItem } from 'integrations/menhir/employee/marketplace/marketplace.types';

export interface IContext {
  cart: IAppleOrderItem[];
  totalCost: TMoney;
  addToCart: (item: IAppleOrderItem) => void;
  updateItem: (itemIdx: number, newItem: IAppleOrderItem) => void;
  removeFromCart: (itemIdx: number) => void;
  clearCart: () => void;
}

export const CartContext = createContext<IContext>({
  cart: [],
  totalCost: {
    amount: 0,
    currency: 'EUR',
  },
  addToCart: () => {},
  updateItem: () => {},
  removeFromCart: () => {},
  clearCart: () => {},
});

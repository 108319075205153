const buildFormDataKeys = (data: any, parentKey: string) => {
  let allKeys: { key: string; value: any }[] = [];
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      const dataKeys = buildFormDataKeys(
        data[key],
        parentKey
          ? Array.isArray(data)
            ? `${parentKey}[]`
            : `${parentKey}[${key}]`
          : key
      );
      allKeys = [...allKeys, ...dataKeys];
    });
  } else {
    const value = data == null ? '' : data;
    allKeys.push({
      key: parentKey,
      value,
    });
  }
  return allKeys;
};

export const getFormDataFromObject = (payload: any, parentKey?: string) => {
  const formData = new FormData();
  const dataKeys = buildFormDataKeys(payload, parentKey ?? '');
  dataKeys.forEach((dataKey) => formData.append(dataKey.key, dataKey.value));
  return formData;
};

import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  GenericModal,
  IconBadge,
  IconLinkSlash,
} from '@coverflex-tech/hypernova';
import { ROUTES } from 'constants/routes';
import css from './invalid-token-modal.module.css';

interface IProps {
  sso?: boolean;
}

const CreatedAccountModal = ({ sso }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onLogin = () => {
    history.push(sso ? ROUTES.SSO_SIGNIN : ROUTES.SIGNIN);
  };

  return (
    <GenericModal
      title={t('signup.invalid_modal.title')}
      iconBadge={
        <IconBadge
          className={css.badge}
          ratio={56}
          icon={<IconLinkSlash className={css.icon} />}
        />
      }
      content={<Trans i18nKey={'signup.invalid_modal.description'} />}
      primaryAction={{
        onClick: onLogin,
        children: t('signup.invalid_modal.login_button'),
      }}
    />
  );
};

export default CreatedAccountModal;

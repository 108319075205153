import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MoneyUtils } from '@coverflex-tech/hypernova-utils';
import AuthContext from 'contexts/authentication/AuthContext';
import { IAppleOrderItem } from 'integrations/menhir/employee/marketplace/marketplace.types';
import { getOrderItemAppleCare } from 'integrations/menhir/employee/marketplace/marketplace.utils';
import { CartContext } from './CartContext';

interface ProviderProps {
  children?: ReactNode;
}
export default function CartProvider({ children }: ProviderProps) {
  const [cart, setCart] = useState<IAppleOrderItem[]>([]);
  const { user } = useContext(AuthContext);

  const addToCart = useCallback((cartItem: IAppleOrderItem) => {
    setCart((prevCart) => [...prevCart, cartItem]);
  }, []);

  const updateItem = useCallback(
    (itemIdx: number, newItem: IAppleOrderItem) => {
      setCart((prevCart) => {
        const newCart = prevCart.slice(0);
        newCart.splice(itemIdx, 1, newItem);
        return newCart;
      });
    },
    []
  );

  const removeFromCart = useCallback((itemIdx: number) => {
    setCart((prevCart) => {
      const newCart = prevCart.slice(0);
      newCart.splice(itemIdx, 1);
      return newCart;
    });
  }, []);

  const clearCart = useCallback(() => {
    setCart([]);
  }, []);

  useEffect(() => {
    if (!user) {
      clearCart();
    }
  }, [user, clearCart]);

  const totalCost = useMemo(
    () =>
      MoneyUtils.sum(
        cart.map((item) => {
          const appleCareAddon = getOrderItemAppleCare(item);
          return appleCareAddon
            ? MoneyUtils.sum([item.productItem.total, appleCareAddon.total])
            : item.productItem.total;
        })
      ),
    [cart]
  );

  const contextValue = {
    cart,
    totalCost,
    addToCart,
    updateItem,
    removeFromCart,
    clearCart,
  };

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  );
}

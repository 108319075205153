import { CompanyDTO } from 'dto/company';
import { EmployeeDTO } from 'integrations/menhir/employee/employee/employee.dto';
import {
  IEmployee,
  IEmployeeIdentification,
  IEmployeeTaxProfile,
  IFamilyMember,
  IMenhirEmployee,
  IMenhirEmployeeActions,
  IMenhirEmployeeAddressInformation,
  IMenhirEmployeeEnvelope,
  IMenhirEmployeeIdentificationEnvelope,
  IMenhirEmployeePersonalInformation,
  IMenhirEmployeeTaxProfile,
  IMenhirFamilyMember,
  IMenhirFamilyMemberListEnvelope,
  IMenhirIdentificationSlug,
  IdentificationDetails,
  TAddOrUpdateEmployeeIdentificationParams,
  TEmployeeReviewPayload,
} from 'integrations/menhir/employee/employee/employee.types';
import { menhirClient } from 'integrations/menhir/menhir.client';
import {
  MENHIR_ENDPOINTS,
  endpointFor,
} from 'integrations/menhir/menhir.endpoints';
import { ICompany } from 'types/company';
import { IIdentificationSlug } from 'types/general';
import { IMenhirCompanyEnvelope } from 'types/menhir/company';
import { removeFalsey } from 'utils/array';
import { DocumentSlugIdentificationTypeMapping } from 'utils/documents';
import { getFormDataFromObject } from 'utils/form';

const getEmployeeDetails = (userId: string): Promise<IEmployee> =>
  menhirClient
    .get<null, IMenhirEmployeeEnvelope>(
      endpointFor(MENHIR_ENDPOINTS.EMPLOYEE.DETAIL.ROOT, userId)
    )
    .then((envelope) => {
      return EmployeeDTO.transformEmployee(envelope.employee);
    });

const updateEmployeeDetails = (
  userId: string,
  employeeData: Partial<IMenhirEmployee>
) =>
  menhirClient
    .patch<null, IMenhirEmployeeEnvelope>(
      endpointFor(MENHIR_ENDPOINTS.EMPLOYEE.DETAIL.ROOT, userId),
      {
        employee: employeeData,
      }
    )
    .then((envelope) => {
      return EmployeeDTO.transformEmployee(envelope.employee);
    });

const updateEmployeePersonalDetails = (
  userId: string,
  employeeData: IMenhirEmployeePersonalInformation
) =>
  menhirClient
    .patch<null, IMenhirEmployeeEnvelope>(
      endpointFor(
        MENHIR_ENDPOINTS.EMPLOYEE.DETAIL.PROFILE.PERSONAL_INFORMATION,
        userId
      ),
      { employee: employeeData }
    )
    .then((envelope) => {
      return EmployeeDTO.transformEmployee(envelope.employee);
    });

const updateEmployeeAddressDetails = (
  userId: string,
  employeeData: IMenhirEmployeeAddressInformation
) =>
  menhirClient
    .patch<null, IMenhirEmployeeEnvelope>(
      endpointFor(
        MENHIR_ENDPOINTS.EMPLOYEE.DETAIL.PROFILE.ADDRESS_INFORMATION,
        userId
      ),
      { employee: employeeData }
    )
    .then((envelope) => {
      return EmployeeDTO.transformEmployee(envelope.employee);
    });

const getFamilyMembers = (): Promise<IFamilyMember[]> =>
  menhirClient
    .get<null, IMenhirFamilyMemberListEnvelope>(
      MENHIR_ENDPOINTS.EMPLOYEE.FAMILY.ROOT
    )
    .then((envelope) => {
      return EmployeeDTO.transformFamilyMemberList(envelope.members);
    });

const createFamilyMember = async (data: Omit<IFamilyMember, 'id'>) => {
  const envelope = await menhirClient.post<
    Omit<IMenhirFamilyMember, 'id'>,
    { member: IMenhirFamilyMember }
  >(
    MENHIR_ENDPOINTS.EMPLOYEE.FAMILY.ROOT,
    getFormDataFromObject({
      member: {
        type: data.type,
        tax_id: data.taxId,
        short_name: data.shortName || data.fullName.split(' ')[0],
        gender: data.gender,
        full_name: data.fullName,
        birth_date: data.birthdate,
        dependant: data.dependant,
        non_sufficient: data.nonSufficient,
        files: data.files.map((file) => file.blob).filter(removeFalsey),
      },
    })
  );
  return EmployeeDTO.transformFamilyMember(envelope.member);
};

const updateFamilyMember = (
  memberId: string,
  details: Partial<IMenhirFamilyMember>
): Promise<IFamilyMember> =>
  menhirClient
    .patch<null, { member: IMenhirFamilyMember }>(
      endpointFor(MENHIR_ENDPOINTS.EMPLOYEE.FAMILY.DETAIL, memberId),
      {
        member: details,
      }
    )
    .then((envelope) => EmployeeDTO.transformFamilyMember(envelope.member));

const getEmployeeCompanyDetails = (): Promise<ICompany> =>
  menhirClient
    .get<null, IMenhirCompanyEnvelope>(MENHIR_ENDPOINTS.EMPLOYEE.COMPANY)
    .then((envelope) => CompanyDTO.transformCompany(envelope.company));

const reviewEmployeeInfo = (
  employeeId: string,
  payload: TEmployeeReviewPayload
) =>
  menhirClient
    .patch<null, IMenhirEmployeeEnvelope>(
      endpointFor(MENHIR_ENDPOINTS.EMPLOYEE.DETAIL.REVIEW, employeeId),
      {
        employee: {
          bank_account: payload.bankAccount
            ? {
                number: payload.bankAccount,
              }
            : undefined,
          address: payload.address
            ? {
                address_line_1: payload.address.addressLine1,
                zipcode: payload.address.zipCode,
                city: payload.address.city,
                country: payload.address.country,
              }
            : undefined,
          phone: payload.phone
            ? {
                country_code: payload.phone.countryCode,
                number: payload.phone.number,
              }
            : undefined,
          name: payload.name,
        },
      }
    )
    .then((envelope) => EmployeeDTO.transformEmployee(envelope.employee));

const getEmployeeIdentification = <
  U extends IEmployeeIdentification<IIdentificationSlug> = IEmployeeIdentification<IIdentificationSlug>
>(
  category: IIdentificationSlug
): Promise<U | null> =>
  menhirClient
    .get<null, IMenhirEmployeeIdentificationEnvelope<typeof category>>(
      endpointFor(MENHIR_ENDPOINTS.EMPLOYEE.IDENTIFICATIONS.DETAIL, category)
    )
    .then((envelope) =>
      envelope.identification
        ? (EmployeeDTO.transformEmployeeIdentification(
            envelope.identification
          ) as U)
        : null
    )
    .catch((e) => {
      if (e.errorCode === 'not_found') {
        return null;
      }
      throw e;
    });

const addOrUpdateEmployeeIdentification = async <
  T extends IMenhirIdentificationSlug,
  U extends IdentificationDetails[T] = IdentificationDetails[T]
>({
  documentType,
  details,
  files,
}: TAddOrUpdateEmployeeIdentificationParams<
  T,
  U
>): Promise<IEmployeeIdentification<T> | null> => {
  const formData = getFormDataFromObject(
    {
      ...details,
      files: files.map((file) => file.blob ?? file.id),
      type: DocumentSlugIdentificationTypeMapping[documentType],
    },
    'identification'
  );
  try {
    const envelope = await menhirClient.post<
      null,
      IMenhirEmployeeIdentificationEnvelope<T>
    >(MENHIR_ENDPOINTS.EMPLOYEE.IDENTIFICATIONS.ROOT, formData);
    return envelope.identification
      ? (EmployeeDTO.transformEmployeeIdentification(
          envelope.identification
        ) as IEmployeeIdentification<T>)
      : null;
  } catch {
    return null;
  }
};

const getEmployeeTaxProfile = () =>
  menhirClient
    .get<null, { tax_profile: IMenhirEmployeeTaxProfile }>(
      MENHIR_ENDPOINTS.EMPLOYEE.TAX_PROFILE
    )
    .then((envelope) =>
      EmployeeDTO.transformEmployeeTaxProfile(envelope.tax_profile)
    );

const buildTaxProfilePayload = (
  params: Omit<IEmployeeTaxProfile, 'incomeRate'>
) => ({
  tax_profile: {
    marital_status: params.maritalStatus,
    salary: {
      amount: params.salary.amount,
      currency: params.salary.currency,
    },
    dependents: params.dependents,
  },
});

const createTaxProfile = (
  params: Omit<IEmployeeTaxProfile, 'incomeRate'>,
  validate?: boolean
) =>
  menhirClient
    .post<null, { tax_profile: IMenhirEmployeeTaxProfile }>(
      MENHIR_ENDPOINTS.EMPLOYEE.TAX_PROFILE,
      buildTaxProfilePayload(params),
      {
        params: {
          validate: validate ?? false,
        },
      }
    )
    .then((envelope) =>
      EmployeeDTO.transformEmployeeTaxProfile(envelope.tax_profile)
    );

const updateTaxProfile = (
  params: Omit<IEmployeeTaxProfile, 'incomeRate'>,
  validate?: boolean
) =>
  menhirClient
    .put<null, { tax_profile: IMenhirEmployeeTaxProfile }>(
      MENHIR_ENDPOINTS.EMPLOYEE.TAX_PROFILE,
      buildTaxProfilePayload(params),
      {
        params: {
          validate: validate ?? false,
        },
      }
    )
    .then((envelope) =>
      EmployeeDTO.transformEmployeeTaxProfile(envelope.tax_profile)
    );

const getEmployeeActions = () =>
  menhirClient
    .get<null, IMenhirEmployeeActions>(MENHIR_ENDPOINTS.EMPLOYEE.ACTIONS)
    .then((actions) => EmployeeDTO.transformEmployeeActions(actions));

export const EmployeeService = {
  getFamilyMembers,
  createFamilyMember,
  updateFamilyMember,
  getEmployeeCompanyDetails,
  getEmployeeIdentification,
  addOrUpdateEmployeeIdentification,
  getEmployeeDetails,
  updateEmployeeDetails,
  updateEmployeePersonalDetails,
  updateEmployeeAddressDetails,
  reviewEmployeeInfo,
  getEmployeeTaxProfile,
  createTaxProfile,
  updateTaxProfile,
  getEmployeeActions,
};

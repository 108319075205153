import {
  transformCard,
  transformCardTokens,
  transformStatus,
} from 'integrations/menhir/employee/card/card.dto';
import { menhirClient } from 'integrations/menhir/menhir.client';
import { MENHIR_ENDPOINTS } from 'integrations/menhir/menhir.endpoints';
import { IAddress } from 'types/general';
import {
  CardStatus,
  ICard,
  IMenhirCardEnvelope,
  IMenhirCardStatus,
  IMenhirCardTokens,
} from './card.types';

export const getEmployeeCard = (): Promise<ICard> =>
  menhirClient
    .get<null, IMenhirCardEnvelope>(MENHIR_ENDPOINTS.EMPLOYEE.CARD.ROOT)
    .then(transformCard);

export const lockCard = (): Promise<CardStatus> =>
  menhirClient
    .patch<null, IMenhirCardStatus>(MENHIR_ENDPOINTS.EMPLOYEE.CARD.LOCK)
    .then(transformStatus);

export const unlockCard = (): Promise<CardStatus> =>
  menhirClient
    .patch<null, IMenhirCardStatus>(MENHIR_ENDPOINTS.EMPLOYEE.CARD.UNLOCK)
    .then(transformStatus);

export const activateCard = (panConfirmation: string): Promise<CardStatus> =>
  menhirClient
    .patch<null, IMenhirCardStatus>(MENHIR_ENDPOINTS.EMPLOYEE.CARD.ACTIVATE, {
      pan_confirmation: panConfirmation,
    })
    .then(transformStatus);

export const cancelCard = (): Promise<CardStatus> =>
  menhirClient
    .delete<null, IMenhirCardStatus>(MENHIR_ENDPOINTS.EMPLOYEE.CARD.ROOT)
    .then(transformStatus);

export const requestCard = (
  shippingAddress: IAddress,
  cardHolderName?: string
): Promise<ICard> =>
  menhirClient
    .post<null, IMenhirCardEnvelope>(MENHIR_ENDPOINTS.EMPLOYEE.CARD.ROOT, {
      card: {
        shipping_address: {
          address_line_1: shippingAddress.addressLine1,
          address_line_2: shippingAddress.addressLine2,
          zipcode: shippingAddress.zipCode,
          type: 'card_shipping_address',
          country: shippingAddress.country,
          city: shippingAddress.city,
          district: shippingAddress.district,
        },
        holder_name: cardHolderName,
      },
    })
    .then(transformCard);

export const requestPin = (): Promise<void> =>
  menhirClient.post(MENHIR_ENDPOINTS.EMPLOYEE.CARD.REQUEST_PIN_SMS);

export const requestPinToken = () =>
  menhirClient
    .get<null, IMenhirCardTokens>(
      MENHIR_ENDPOINTS.EMPLOYEE.CARD.PIN.ONE_TIME_TOKEN
    )
    .then(transformCardTokens);

export const resetPin = ({
  encryptedPin,
  oneTimeToken,
}: {
  encryptedPin: string;
  oneTimeToken: string;
}) =>
  menhirClient.post<
    null,
    null,
    {
      encrypted_pin: string;
      one_time_token: string;
    }
  >(MENHIR_ENDPOINTS.EMPLOYEE.CARD.PIN.SET, {
    encrypted_pin: encryptedPin,
    one_time_token: oneTimeToken,
  });

export const validateCardName = (cardHolderName: string) =>
  menhirClient.post(
    MENHIR_ENDPOINTS.EMPLOYEE.CARD.ROOT,
    {
      card: { holder_name: cardHolderName },
    },
    {
      params: {
        validate: true,
      },
    }
  );

export const CardService = {
  getEmployeeCard,
  lockCard,
  unlockCard,
  activateCard,
  cancelCard,
  requestCard,
  requestPin,
  requestPinToken,
  resetPin,
  validateCardName,
};

import { ROUTES } from 'constants/routes';
import { useGetCard } from 'integrations/menhir/employee/card/card.hooks';
import { useGetEmployeeActions } from 'integrations/menhir/employee/employee/employee.hooks';
import { useGetFeatures } from 'integrations/menhir/employee/features/features.hooks';

export function useHomeRedirect() {
  const {
    features: { FF_CARD, FF_RECARDING_IT },
  } = useGetFeatures();
  const getCard = useGetCard({ enabled: FF_CARD });
  const getEmployeeActions = useGetEmployeeActions({
    enabled: FF_RECARDING_IT,
  });

  const loading =
    (FF_CARD && !getCard.card && !getCard.error) ||
    getEmployeeActions.isInitialLoading;

  if (FF_CARD && !loading) {
    if (!getCard.card || getEmployeeActions.actions?.cardRequestRequired) {
      return {
        loadingRoute: loading,
        route: ROUTES.CARD_REQUEST,
      };
    }
  }

  return {
    loadingRoute: loading,
  };
}

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FloatingDigitsField,
  IconLoader,
} from '@coverflex-tech/hypernova';
import { ResendCode } from 'containers/Authentication/containers/SignIn/components/OTPForm/components/ResendCode/ResendCode';
import {
  useOTPConfirmation,
  usePhoneNumberSetup,
} from 'integrations/menhir/employee/sessions/sessions.hooks';
import { ISignInResponse } from 'integrations/menhir/employee/sessions/sessions.types';
import css from './CodeForm.module.css';

interface IProps {
  token: string;
  phoneNumber: string;
  onBack: () => void;
  onConfirm: (response: ISignInResponse) => void;
}
export const CodeForm = ({ token, phoneNumber, onBack, onConfirm }: IProps) => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const { mutateAsync: confirmOTP, isLoading } = useOTPConfirmation();
  const { mutateAsync: triggerResend } = usePhoneNumberSetup();

  const onSubmit = async (value: string) => {
    try {
      const response = await confirmOTP({ invitationCode: token, otp: value });
      if (response) {
        onConfirm(response);
      }
    } catch {
      setCode('');
      setError('authentication.signin.form.mfa.otp.error');
    }
  };

  const onResend = () => {
    triggerResend({ invitationCode: token, phoneNumber });
  };

  return (
    <div>
      <div className={css.field}>
        <FloatingDigitsField
          id="otpCode"
          label={t('authentication.signin.form.mfa.otp.code_label')}
          value={code}
          disabled={isLoading}
          onChange={(value: string) => {
            setCode(value);
            if (/^\d{6}$/.test(value)) {
              onSubmit(value);
            }
          }}
          error={t(error)}
        />
        {isLoading && <IconLoader className={css.loaderIcon} />}
      </div>
      <div className={css.actions}>
        <Button variant="secondary" onClick={onBack} disabled={isLoading}>
          {t('general.navigation.back')}
        </Button>
        <ResendCode variant="button" disabled={isLoading} onResend={onResend} />
      </div>
    </div>
  );
};

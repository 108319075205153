import {
  BrowserTracing,
  init,
  reactRouterV5Instrumentation,
} from '@sentry/react';

const setupReporter = (history: History) => {
  init({
    environment: import.meta.env.VITE_ENV,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: reactRouterV5Instrumentation(history),
      }),
    ],

    tracesSampleRate: 0.1,
  });
};

export const ErrorReportService = {
  setupReporter,
};

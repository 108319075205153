import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import { ERRORS } from 'constants/errors';
import { ROUTES } from 'constants/routes';
import { LocalStorageService } from 'integrations/local-storage/local-storage.service';
import {
  setGetEmployeeQueryData,
  useGetEmployeeCompanyDetails,
  useGetEmployeeDetails,
} from 'integrations/menhir/employee/employee/employee.hooks';
import { IEmployee } from 'integrations/menhir/employee/employee/employee.types';
import { IUser } from 'integrations/menhir/employee/sessions/sessions.types';
import { LoadingPlaceholder } from 'components/LoadingPlaceholder/LoadingPlaceholder';
import { UserDetailsContext } from './UserDetailsContext';

interface Props {
  user: IUser;
  children: ReactNode;
}

const UserDetailsProvider = ({ user, children }: Props) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const getEmployeeDetails = useGetEmployeeDetails(user.id, { retry: 2 });
  const getCompanyDetails = useGetEmployeeCompanyDetails({ retry: 2 });

  const updateEmployee = useCallback(
    (employee: IEmployee) => {
      setGetEmployeeQueryData(user.id, employee);
      if (employee.language && employee.language !== i18n.language) {
        i18n.changeLanguage(employee.language);
        LocalStorageService.setLanguage(employee.language);
      }
    },
    [i18n, user.id]
  );

  if (
    getEmployeeDetails.isInitialLoading ||
    getCompanyDetails.isInitialLoading
  ) {
    return <LoadingPlaceholder />;
  }

  if (!getEmployeeDetails.employee || !!getEmployeeDetails.error) {
    if (getEmployeeDetails.error) {
      throw getEmployeeDetails.error;
    }
    throw new Error(ERRORS.EMPLOYEE_REQUIRED);
  }

  if (!getCompanyDetails.company || !!getCompanyDetails.error) {
    if (getCompanyDetails.error) {
      throw getCompanyDetails.error;
    }
    throw new Error(ERRORS.COMPANY_REQUIRED);
  }

  if (
    location.pathname !== ROUTES.ONBOARDING &&
    getEmployeeDetails.employee.invitation === 'pending_review'
  ) {
    return <Redirect push to={ROUTES.ONBOARDING} />;
  }

  return (
    <UserDetailsContext.Provider
      value={{
        employee: getEmployeeDetails.employee,
        company: getCompanyDetails.company,
        updateEmployee,
      }}
    >
      {children}
    </UserDetailsContext.Provider>
  );
};

export default UserDetailsProvider;

import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AuthService } from 'integrations/menhir/employee/sessions/sessions.service';

export function usePhoneNumberSetup(
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof AuthService.setupPhone>>,
    any,
    { invitationCode: string; phoneNumber: string }
  >
) {
  return useMutation({
    mutationFn: ({ invitationCode, phoneNumber }) =>
      AuthService.setupPhone(invitationCode, phoneNumber),
    ...options,
  });
}

export function useOTPConfirmation(
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof AuthService.confirmSignUp>>,
    any,
    { invitationCode: string; otp: string }
  >
) {
  return useMutation({
    mutationFn: ({ invitationCode, otp }) =>
      AuthService.confirmSignUp(invitationCode, otp),
    ...options,
  });
}

import { ReactNode } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import {
  Alert,
  Button,
  FloatingPasswordField,
  Text,
} from '@coverflex-tech/hypernova';
import { zodResolver } from '@hookform/resolvers/zod';
import { PasswordValidatorType } from 'types/general';
import { getPasswordValidation } from 'utils/password';
import { ControlledPasswordField } from 'components/Fields/ControlledPasswordField/ControlledPasswordField';
import { ICreatePasswordFormData, formSchema } from './schema';
import { css } from './CreatePasswordForm.css';

export interface IFeedback {
  type: 'error' | 'success';
  message: string;
}

interface IProps {
  token: string;
  disclaimer?: ReactNode;
  buttonLabel: string;
  submitting?: boolean;
  feedback?: IFeedback;
  onSubmit: (form: ICreatePasswordFormData) => Promise<void>;
}

export const CreatePasswordForm = ({
  token,
  disclaimer,
  buttonLabel,
  submitting,
  feedback,
  onSubmit,
}: IProps) => {
  const { t } = useTranslation();

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      password: '',
      passwordConfirmation: '',
      token,
    },
    resolver: zodResolver(formSchema),
  });

  return (
    <FormProvider {...methods}>
      <form className={css.form} onSubmit={methods.handleSubmit(onSubmit)}>
        <div className={css.fields}>
          <Controller
            name="token"
            render={({ field }) => (
              <input
                type="hidden"
                name="token"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="password"
            render={({ field: { ref, ...field }, fieldState }) => {
              const passwordHint = getPasswordHint(field.value);
              return (
                <FloatingPasswordField
                  {...field}
                  id="password"
                  label={t('authentication.create_password.password.label')}
                  hint={passwordHint}
                  error={fieldState.error?.message ? passwordHint : ''}
                />
              );
            }}
          />
          <ControlledPasswordField
            label={t(
              'authentication.create_password.password_confirmation.label'
            )}
            name="passwordConfirmation"
          />
        </div>
        {!submitting && feedback && (
          <Alert type={feedback.type}>{feedback.message}</Alert>
        )}
        {disclaimer && (
          <Text size="sm" className={css.disclaimer}>
            {disclaimer}
          </Text>
        )}
        <Button type="submit" loading={submitting} className={css.button}>
          {t(buttonLabel)}
        </Button>
      </form>
    </FormProvider>
  );
};

export const getPasswordHint = (password: string) => {
  const passwordHintClass = (regex: PasswordValidatorType) => {
    if (!password) return '';
    return getPasswordValidation(password).validRegexes.includes(regex)
      ? css.hintValid
      : css.hintInvalid;
  };

  return (
    <Text size="sm">
      <Trans i18nKey="authentication.create_password.hint">
        &nbsp;
        <span className={passwordHintClass('length')} />
        <span className={passwordHintClass('uppercase')} />
        <span className={passwordHintClass('special_char')} />
        <span className={passwordHintClass('lowercase')} />
      </Trans>
    </Text>
  );
};

import { ERROR_I18N } from 'constants/errors';

const getErrorMessageKey = (errorCode: string) => {
  return ERROR_I18N[errorCode] || 'errors.api.generic';
};

export const isErrorObject = (err?: any): err is { errors: object } =>
  !!err?.errors && typeof err.errors === 'object';

export default {
  getErrorMessageKey,
};

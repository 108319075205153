import { useLocation } from 'react-router-dom';
import { AuthenticationLayout } from 'layouts/AuthenticationLayout/AuthenticationLayout';
import { parse } from 'query-string';
import { ConfirmReset } from './containers/ConfirmReset/ConfirmReset';
import { RequestReset } from './containers/RequestReset/RequestReset';

export const ResetPassword = () => {
  const location = useLocation();

  const { token } = parse(location.search);
  return (
    <AuthenticationLayout>
      {!token ? <RequestReset /> : <ConfirmReset token={token as string} />}
    </AuthenticationLayout>
  );
};

import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { queryClient } from 'contexts/react-query/ReactQueryProvider';
import { EmployeeService } from 'integrations/menhir/employee/employee/employee.service';
import {
  IEmployee,
  IEmployeeActions,
  IEmployeeIdentification,
  IFamilyMember,
  IMenhirEmployee,
  IMenhirIdentificationSlug,
  IdentificationDetails,
  TAddOrUpdateEmployeeIdentificationParams,
  TEmployeeReviewPayload,
} from 'integrations/menhir/employee/employee/employee.types';
import { MENHIR_ENDPOINTS } from 'integrations/menhir/menhir.endpoints';
import { ICompany } from 'types/company';
import { IIdentificationSlug } from 'types/general';

export const useGetEmployeeDetails = (
  userId: string,
  options?: UseQueryOptions<IEmployee>
) => {
  const { data: employee, ...rest } = useQuery({
    queryKey: [MENHIR_ENDPOINTS.EMPLOYEE.DETAIL.ROOT, userId],
    queryFn: () => EmployeeService.getEmployeeDetails(userId),
    ...options,
  });

  return { employee, ...rest };
};

export const setGetEmployeeQueryData = (
  userId: string,
  employeeData: IEmployee
) => {
  return queryClient.setQueryData<IEmployee>(
    [MENHIR_ENDPOINTS.EMPLOYEE.DETAIL.ROOT, userId],
    employeeData
  );
};

export const useGetEmployeeCompanyDetails = (
  options?: UseQueryOptions<ICompany>
) => {
  const { data: company, ...rest } = useQuery({
    queryKey: [MENHIR_ENDPOINTS.EMPLOYEE.COMPANY],
    queryFn: EmployeeService.getEmployeeCompanyDetails,
    ...options,
  });

  return { company, ...rest };
};

export const setGetEmployeeCompanyQueryData = (companyData: ICompany) => {
  return queryClient.setQueryData<ICompany>(
    [MENHIR_ENDPOINTS.EMPLOYEE.COMPANY],
    companyData
  );
};

export const useUpdateEmployeeDetails = (
  options?: UseMutationOptions<
    IEmployee,
    any,
    {
      employeeId: string;
      payload: Partial<IMenhirEmployee>;
    }
  >
) => {
  return useMutation<
    IEmployee,
    unknown,
    { employeeId: string; payload: Partial<IMenhirEmployee> }
  >({
    mutationFn: ({ employeeId, payload }) =>
      EmployeeService.updateEmployeeDetails(employeeId, payload),
    onSuccess: (updatedEmployee) => {
      setGetEmployeeQueryData(updatedEmployee.id, updatedEmployee);
    },
    ...options,
  });
};

export const useReviewEmployeeInfoMutation = (
  options?: UseMutationOptions<
    IEmployee,
    any,
    {
      employeeId: string;
      payload: TEmployeeReviewPayload;
    }
  >
) => {
  return useMutation({
    mutationFn: ({ employeeId, payload }) =>
      EmployeeService.reviewEmployeeInfo(employeeId, payload),
    onSuccess: (updatedEmployee) => {
      setGetEmployeeQueryData(updatedEmployee.id, updatedEmployee);
    },
    ...options,
  });
};

export const useGetFamilyMembers = (
  options?: UseQueryOptions<IFamilyMember[]>
) => {
  const { data: familyMembers, ...rest } = useQuery({
    queryKey: [MENHIR_ENDPOINTS.EMPLOYEE.FAMILY.ROOT],
    queryFn: EmployeeService.getFamilyMembers,
    ...options,
  });
  return { familyMembers, ...rest };
};

export const updateFamilyMembersData = (updatedMember: IFamilyMember) => {
  queryClient.setQueryData<IFamilyMember[]>(
    [MENHIR_ENDPOINTS.EMPLOYEE.FAMILY.ROOT],
    (oldData) => {
      if (Array.isArray(oldData)) {
        const indexChanged = oldData.findIndex(
          (member) => member.id === updatedMember.id
        );

        if (indexChanged !== -1) {
          return [
            ...oldData.slice(0, indexChanged),
            updatedMember,
            ...oldData.slice(indexChanged + 1),
          ];
        }
      }
      return oldData;
    }
  );
};

export const useAddFamilyMember = (
  options?: UseMutationOptions<
    IFamilyMember,
    unknown,
    Omit<IFamilyMember, 'id'>
  >
) => {
  return useMutation(EmployeeService.createFamilyMember, options);
};

export const useGetEmployeeActions = (
  options?: UseQueryOptions<IEmployeeActions>
) => {
  const { data: actions, ...rest } = useQuery({
    queryKey: [MENHIR_ENDPOINTS.EMPLOYEE.ACTIONS],
    queryFn: EmployeeService.getEmployeeActions,
    ...options,
  });

  return { actions, ...rest };
};

export const resetGetEmployeeActionsQueryData = () => {
  queryClient.resetQueries([MENHIR_ENDPOINTS.EMPLOYEE.ACTIONS]);
};

export const useGetEmployeeIdentification = <
  T extends IIdentificationSlug,
  U extends IEmployeeIdentification<T> = IEmployeeIdentification<T>
>(
  category: T,
  options?: UseQueryOptions<U | null>
) => {
  const { data: employeeIdentification, ...rest } = useQuery({
    queryKey: [MENHIR_ENDPOINTS.EMPLOYEE.IDENTIFICATIONS.DETAIL, category],
    queryFn: () => EmployeeService.getEmployeeIdentification<U>(category),
    ...options,
  });

  return { employeeIdentification, ...rest };
};

export function useAddOrUpdateEmployeeIdentification<
  T extends IMenhirIdentificationSlug,
  U extends IdentificationDetails[T] = IdentificationDetails[T]
>(
  options?: UseMutationOptions<
    IEmployeeIdentification<T> | null,
    any,
    TAddOrUpdateEmployeeIdentificationParams<T, U>
  >
) {
  return useMutation({
    mutationFn: EmployeeService.addOrUpdateEmployeeIdentification,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([
        MENHIR_ENDPOINTS.EMPLOYEE.IDENTIFICATIONS.DETAIL,
        variables?.documentType,
      ]);
    },
    ...options,
  });
}

export const useGetEmployeeTaxProfile = (
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof EmployeeService.getEmployeeTaxProfile>>,
    any
  >
) => {
  const { data: taxProfile, ...rest } = useQuery({
    queryKey: [MENHIR_ENDPOINTS.EMPLOYEE.TAX_PROFILE],
    queryFn: EmployeeService.getEmployeeTaxProfile,
    ...options,
  });

  return { taxProfile, ...rest };
};

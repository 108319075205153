import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router-dom';
import { TMarket } from 'constants/markets';
import { ROUTES, TRoute } from 'constants/routes';
import { queryClient } from 'contexts/react-query/ReactQueryProvider';
import { getDefaultLang } from 'i18n';
import { AnalyticsService } from 'integrations/analytics/analytics.service';
import { LocalStorageService } from 'integrations/local-storage/local-storage.service';
import {
  setGetEmployeeCompanyQueryData,
  setGetEmployeeQueryData,
} from 'integrations/menhir/employee/employee/employee.hooks';
import { EmployeeService } from 'integrations/menhir/employee/employee/employee.service';
import { tokenToUser } from 'integrations/menhir/employee/sessions/sessions.dto';
import { AuthService } from 'integrations/menhir/employee/sessions/sessions.service';
import { IUser } from 'integrations/menhir/employee/sessions/sessions.types';
import AuthContext from './AuthContext';

interface ReactProps {
  children: ReactNode;
}

const initialState = (() => {
  const token = LocalStorageService.getAuthToken();
  if (token) {
    try {
      return tokenToUser(LocalStorageService.decodeAuthToken(token));
    } catch {
      LocalStorageService.clearTokens();
      return undefined;
    }
  }
  return undefined;
})();

const getRedirectRoute = (initialRoute: string) => {
  const excludedRoutes = [
    ROUTES.RESET_PASSWORD,
    ROUTES.SIGNUP,
    ROUTES.SIGNIN,
    ROUTES.SSO_SIGNIN,
    ROUTES.ROOT,
  ] as TRoute[];
  const allRoutes = Object.values(ROUTES).filter(
    (route) => !excludedRoutes.includes(route)
  );
  const matchedRoute = allRoutes.find((route) =>
    matchPath(initialRoute, {
      path: route,
      exact: true,
    })
  );
  return matchedRoute ? initialRoute : '';
};

const AuthProvider = ({ children }: ReactProps) => {
  const { i18n } = useTranslation();
  const [user, setUser] = useState(initialState);
  const [checked, setChecked] = useState(false);
  const redirectRouteRef = useRef(getRedirectRoute(window.location.pathname));

  const signIn = useCallback(
    (newUser: IUser) => {
      queryClient.clear();
      setUser(newUser);
    },
    [setUser]
  );

  const signOut = useCallback(() => {
    redirectRouteRef.current = '';
    setUser(undefined);
  }, [setUser]);

  useEffect(() => {
    if (user) {
      (async () => {
        try {
          const employeeDetails = await EmployeeService.getEmployeeDetails(
            user.id
          );
          const companyDetails =
            await EmployeeService.getEmployeeCompanyDetails();
          setGetEmployeeQueryData(user.id, employeeDetails);
          setGetEmployeeCompanyQueryData(companyDetails);
          AnalyticsService.trackIdentification(employeeDetails, companyDetails);
          if (
            !['pending_review', 'accepted', 'left'].includes(
              employeeDetails.invitation ?? ''
            )
          ) {
            await AuthService.signOut();
          }
          if (employeeDetails.invitation === 'pending_review') {
            AnalyticsService.trackCustomEvent('onboarding_started');
          }
          const language = employeeDetails.language ?? getDefaultLang();
          i18n.changeLanguage(language);
          LocalStorageService.setLanguage(language);
          setChecked(true);
        } catch {
          await AuthService.signOut();
          signOut();
        }
      })();
    }
  }, [user, i18n, signOut]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const marketParam = urlParams.get('market') as TMarket;
    if (marketParam) {
      AnalyticsService.setChatConversationFields(marketParam);
    }
  }, []);

  const contextValue = {
    user,
    signIn,
    signOut,
    initialRoute: redirectRouteRef.current,
  };

  if (user && !checked) {
    return null;
  }

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;

import {
  IEmployee,
  IEmployeeActions,
  IEmployeeIdentification,
  IEmployeeTaxProfile,
  IFamilyMember,
  IMenhirCitizenCard,
  IMenhirEmployee,
  IMenhirEmployeeActions,
  IMenhirEmployeeIdentification,
  IMenhirEmployeeTaxProfile,
  IMenhirFamilyMember,
  IMenhirIdentificationSlug,
  IMenhirTransportationCard,
  IdentificationDetails,
} from 'integrations/menhir/employee/employee/employee.types';
import { IAddress, IIdentificationSlug } from 'types/general';
import { IMenhirAddress } from 'types/menhir/general';

const transformAddress = (address?: IMenhirAddress): IAddress => ({
  addressLine1: address?.address_line_1 ?? '',
  addressLine2: address?.address_line_2,
  city: address?.city ?? '',
  country: address?.country ?? '',
  zipCode: address?.zipcode ?? '',
  type: address?.type,
});

const isCitizenCardIdentification = (
  identification: IMenhirEmployeeIdentification<IMenhirIdentificationSlug>
): identification is IMenhirCitizenCard =>
  identification.type === 'citizen_card';

const isTransportationCard = (
  identification: IMenhirEmployeeIdentification<IMenhirIdentificationSlug>
): identification is IMenhirTransportationCard =>
  identification.type === 'transportation_card';

const transformEmployeeIdentificationDetails = <T extends IIdentificationSlug>(
  identification: IMenhirEmployeeIdentification<T>
) => {
  if (isCitizenCardIdentification(identification)) {
    return {
      number: identification.number,
    } as IdentificationDetails['citizen-card'];
  }
  if (isTransportationCard(identification)) {
    return {
      number: identification.number,
      expires_at: identification.expires_at,
    } as IdentificationDetails['transportation-card'];
  }
  return undefined;
};

const transformEmployee = (employee: IMenhirEmployee): IEmployee => ({
  id: employee.id,
  name: employee.name,
  email: employee.email,
  phone: employee.phone,
  gender: employee.gender,
  birthDate: employee.birthdate,
  shortName: employee.short_name,
  taxId: employee.tax_id,
  nationality: employee.nationality,
  address: transformAddress(employee.address),
  bankAccount: employee.bank_account,
  companyId: employee.company_id,
  invitation: employee.invitation?.status,
  language: employee.language,
  hasActiveSubscriptionEnrollment: employee.has_active_subscription_enrollment,
  birthplace: employee.birthplace,
  profession: employee.profession,
  fiscalRegionName: employee.fiscal_region_name,
  leavingAt: employee.leaving_at,
});

const transformFamilyMember = (
  menhirFamilyMember: IMenhirFamilyMember
): IFamilyMember => ({
  id: menhirFamilyMember.id,
  shortName: menhirFamilyMember.short_name,
  fullName: menhirFamilyMember.full_name,
  birthdate: menhirFamilyMember.birth_date,
  gender: menhirFamilyMember.gender ?? undefined,
  taxId: menhirFamilyMember.tax_id,
  type: menhirFamilyMember.type,
  priceEstimation: menhirFamilyMember.price_estimation,
  dependant: menhirFamilyMember.dependant ?? undefined,
  nonSufficient: menhirFamilyMember.non_sufficient ?? undefined,
  files: menhirFamilyMember.files ?? [],
});

const transformFamilyMemberList = (
  menhirFamilyMembers: IMenhirFamilyMember[]
): IFamilyMember[] => menhirFamilyMembers.map(transformFamilyMember);

const transformEmployeeIdentification = <T extends IMenhirIdentificationSlug>(
  identification: IMenhirEmployeeIdentification<T>
): IEmployeeIdentification<T> => ({
  id: identification.id,
  type: identification.type,
  verified: identification.verified,
  files: identification.files?.map((file) => ({
    id: file.id,
    name: file.name,
    slug: file.slug,
    url: file.url,
  })),
  details: transformEmployeeIdentificationDetails(
    identification
  ) as IdentificationDetails[T],
});

const transformEmployeeIdentifications = <T extends IMenhirIdentificationSlug>(
  identifications: IMenhirEmployeeIdentification<T>[]
) => identifications.map(transformEmployeeIdentification);
const transformEmployeeTaxProfile = (
  profile: IMenhirEmployeeTaxProfile
): IEmployeeTaxProfile => ({
  salary: profile.salary,
  dependents: profile.dependents,
  maritalStatus: profile.marital_status,
  incomeRate: profile.income_rate,
});

export const transformEmployeeActions = (
  actions: IMenhirEmployeeActions
): IEmployeeActions => ({
  cardRequestRequired: actions.card_request_required,
});

export const EmployeeDTO = {
  transformAddress,
  transformEmployee,
  transformFamilyMember,
  transformFamilyMemberList,
  transformEmployeeIdentifications,
  transformEmployeeIdentification,
  transformEmployeeTaxProfile,
  transformEmployeeActions,
};

export const ROUTES = {
  ROOT: '/',
  SIGNIN: '/signin',
  SSO_SIGNIN: '/oauth2/signin',
  SIGNUP: '/signup',
  RESET_PASSWORD: '/reset',
  MEAL: '/meal',
  MEAL_VOUCHER: '/meal-voucher',
  MEAL_VOUCHER_EXPENSE: '/meal-voucher/expense/:id',
  MEAL_NETWORK: '/meal-network',
  MEAL_NETWORK_REQUEST: '/meal-network/request',
  SAVINGS: '/savings',
  BENEFITS: '/benefits',
  BENEFITS_ACTIVITY: '/benefits/activity',
  INSURANCE: '/insurance',
  INSURANCE_UPGRADE_PLAN: '/insurance/upgrade-plan',
  INSURANCE_DETAIL: '/insurance/:policyId',
  INSURANCE_FAMILY_MEMBER_PAYMENT: '/insurance/:policyId/:memberId/payment',
  FORGOT_PASSWORD: '/forgot',
  CARD: '/profile/card',
  CARD_REQUEST: '/profile/card/request',
  PROFILE: '/profile',
  PERSONAL_DETAILS: '/profile/personal-details',
  COMPANY_DETAILS: '/profile/company-details',
  BENEFITS_POLICY: '/profile/benefits-policy',
  FAMILY_MEMBERS: '/profile/family',
  FAMILY_MEMBER_DETAILS: '/profile/family/:memberId',
  ADD_FAMILY_MEMBER: '/profile/family/add',
  SETTINGS: '/profile/settings',
  DOCUMENTS: '/profile/documents',
  DOCUMENT_DETAILS: '/profile/documents/:documentType',
  DOCUMENT_EDIT: '/profile/documents/:documentType/edit',
  TAX_PROFILE: '/profile/tax-profile',
  CARD_ACTIVATION: '/profile/card/activate',
  ONBOARDING: '/review',
  ADD_INSURANCE_MEMBERS: '/insurance/:policyId/add-members',
  CHILDCARE_VOUCHER: '/benefits/childcare-voucher',
  CHILDCARE_VOUCHER_DETAILS: '/benefits/childcare-voucher/:voucherId',
  CHILDCARE_PAY: '/benefits/childcare/pay',
  CHILDCARE_ACTIVITY_DETAIL: '/benefits/childcare/activity/:operationId',
  ADD_SCHOOL: '/benefits/childcare-voucher/add-school',
  BENEFIT_HEALTH: '/benefits/health-wellbeing',
  BENEFIT_GYM_FITNESS: '/benefits/gym-fitness',
  BENEFIT_EDUCATION_TRAINING: '/benefits/education-training',
  EDUCATION_VOUCHER_DETAILS: '/benefits/education-training/:voucherId',
  BENEFIT_SENIOR_EXPENSES: '/benefits/senior-expenses',
  BENEFIT_TECHNOLOGY: '/benefits/technology',
  BENEFIT_PROFESSIONAL_TRAINING: '/benefits/professional-training',
  BENEFIT_TRANSPORTATION: '/benefits/transportation',
  BENEFIT_PARKING: '/benefits/parking',
  BENEFIT_DONATIONS: '/benefits/donations',
  DONATION_DETAIL: '/benefits/donation/:orderId',
  NEW_DONATION: '/benefits/donation/new',
  BENEFIT_SUMMER_CAMP: '/benefits/summer-camp',
  BENEFIT_OTHER_BENEFITS: '/benefits/other-benefits',
  BENEFIT_CASHOUT: '/benefits/cashout',
  BENEFIT_DETAIL: '/benefits/:slug',
  NEW_REIMBURSEMENT: '/benefits/reimbursements/new',
  REIMBURSEMENT_DETAILS: '/benefits/reimbursements/:categorySlug/:orderId',
  REIMBURSEMENT_PRODUCTS: '/benefits/reimbursements/:categorySlug/products',
  RETIREMENT_INVESTMENT: '/benefits/retirement-investment',
  RETIREMENT_INVESTMENT_DETAIL: '/benefits/retirement-investment/:orderId',
  NEW_RETIREMENT_INVESTMENT: '/benefits/retirement-investment/new',
  RETIREMENT_INVESTMENT_TOP_UP:
    '/benefits/retirement-investment/:orderId/top-up',
  NEW_APPLE_ORDER: '/benefits/technology/apple/new',
  APPLE_ORDER_DETAIL: '/benefits/technology/apple/:orderId',
  NEW_EDUCATION_VOUCHER: '/benefits/education-training/new',
  BENEFITS_OPTIN: '/benefits/opt-in',
  DISCOUNTS: '/discounts',
  ACTIVITY: '/activity',
  SCHOOLS_NETWORK: '/schools-network',
  BUDGETS: '/budgets',
  BUDGETS_DETAIL: '/budgets/:budgetId',
  NEW_BUDGET_REIMBURSEMENT: '/budgets/reimbursement/new',
  BUDGET_REIMBURSEMENT_DETAILS: '/budgets/reimbursements/:orderId',
  BUDGET_EXTERNAL_EXPENSE:
    '/budgets/:budgetId/budget_employees/:budgetEmployeeId/expenses/:expenseId',
  BUDGET_APPLICATIONS: '/budgets/applications',
  BUDGET_APPLICATION_REQUIREMENTS:
    '/budgets/applications/:budgetId/requirements',
  BUDGET_APPLICATION_DETAILS: '/budgets/applications/:budgetId/:applicationId',
  NEW_BUDGET_APPLICATION: '/budgets/applications/:budgetId/new',
  NEW_BUDGET_CHILDCARE_VOUCHER: '/budgets/childcare/:budgetEmployeeId/new',
  CREDITS: '/credits',
  CREDITS_ADD: '/credits/add',
  CREDITS_ADD_CARD: '/credits/add-card',
  WELFARE: '/welfare',
  WELFARE_BENEFIT: '/welfare/:slug',
  WELFARE_CATEGORIES: '/welfare/:slug/categories',
  WELFARE_CATEGORY: '/welfare/:slug/categories/:category',
  WELFARE_REIMBURSEMENT: '/welfare/reimbursement/:slug',
  WELFARE_CONTRIBUTION: '/welfare/contribution/:slug',
  WELFARE_REIMBURSEMENT_DETAIL: '/welfare/reimbursement_expense/:orderId',
  MEAL_VOUCHER_USAGE: '/meal-voucher/usage',
  EWA: '/ewa',
  EWA_TRANSFER: '/ewa/transfer',
  EWA_SCHEDULE: '/ewa/schedule',
  EWA_SCHEDULE_EDIT: '/ewa/schedule/edit',
  NOT_FOUND: '/not-found',
} as const satisfies Record<string, string>;

export type TRoute = (typeof ROUTES)[keyof typeof ROUTES];

export type TRouteParamsArray<R extends string> =
  R extends `${string}:${infer P2}` ? [string, ...TRouteParamsArray<P2>] : [];

export type TRouteParamsRecord<Path> =
  Path extends `${infer Segment}/${infer Rest}`
    ? Segment extends `:${infer Param}`
      ? Record<Param, string> & TRouteParamsRecord<Rest>
      : TRouteParamsRecord<Rest>
    : Path extends `:${infer Param}`
    ? Record<Param, string>
    : {};

export const routeFor = <R extends string = TRoute>(
  route: R,
  ...args: TRouteParamsArray<R>
) => {
  return args.reduce<string>((r, arg) => r.replace(/:\w+/, arg), route);
};

import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import {
  Alert,
  Button,
  Heading,
  IconArrowLeftLong,
} from '@coverflex-tech/hypernova';
import { zodResolver } from '@hookform/resolvers/zod';
import { ROUTES } from 'constants/routes';
import { usePageViewTracking } from 'hooks/usePageViewTracking';
import { AnalyticsService } from 'integrations/analytics/analytics.service';
import { AuthService } from 'integrations/menhir/employee/sessions/sessions.service';
import { ControlledTextField } from 'components/Fields/ControlledTextField/ControlledTextField';
import { ControllerContext } from 'components/Fields/ControllerContext/ControllerContext';
import { IResetPasswordFormData, formSchema } from './schema';
import { css } from './RequestReset.css';

export const RequestReset = () => {
  const { t } = useTranslation();
  const history = useHistory();
  usePageViewTracking('recover_password_page');

  const [success, setSuccess] = useState(false);

  const onSubmit = async (form: IResetPasswordFormData) => {
    AnalyticsService.trackCustomEvent('recover_password_button_clicked');
    const { email } = form;
    try {
      await AuthService.requestPasswordReset(email);
      setSuccess(true);
    } catch (err: any) {
      const { statusCode } = err;
      if (statusCode === 422) {
        setSuccess(true);
      }
    }
  };

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(formSchema),
  });

  return (
    <div className={css.host}>
      {!success && (
        <Link to={ROUTES.SIGNIN}>
          <Button
            variant="tertiary"
            icon={<IconArrowLeftLong />}
            presentational
          >
            {t('general.navigation.back')}
          </Button>
        </Link>
      )}
      <Heading level={3}>{t('authentication.reset_password.title')}</Heading>
      {success ? (
        <>
          <Alert type="success" className={css.feedback}>
            <Trans
              i18nKey="authentication.reset_password.success_message"
              values={{ email: methods.getValues().email }}
            />
          </Alert>
          <Button
            className={css.button}
            onClick={() => history.push(ROUTES.SIGNIN)}
          >
            {t('authentication.reset_password.signin')}
          </Button>
        </>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className={css.host}>
            <ControlledTextField
              name="email"
              type="email"
              label={t('authentication.reset_password.email.label')}
            />
            <ControllerContext
              render={({ formState: { isSubmitting } }) => (
                <Button
                  type="submit"
                  loading={isSubmitting}
                  className={css.button}
                >
                  {t('authentication.reset_password.button')}
                </Button>
              )}
            />
          </form>
        </FormProvider>
      )}
    </div>
  );
};

import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Text } from '@coverflex-tech/hypernova';
import { zodResolver } from '@hookform/resolvers/zod';
import cx from 'classnames';
import {
  OTPFormSchema,
  schema,
} from 'containers/Authentication/containers/SignIn/components/OTPForm/schema';
import { AnalyticsService } from 'integrations/analytics/analytics.service';
import { AuthService } from 'integrations/menhir/employee/sessions/sessions.service';
import {
  IOTPRequiredStatus,
  ISignInResponse,
} from 'integrations/menhir/employee/sessions/sessions.types';
import { MFALayout } from 'layouts/MFALayout/MFALayout';
import { ControlledCheckbox } from 'components/Fields/ControlledCheckbox/ControlledCheckbox';
import { ControlledDigitsField } from 'components/Fields/ControlledDigitsField/ControlledDigitsField';
import { Title } from 'components/Typography';
import { ResendCode } from './components/ResendCode/ResendCode';
import css from './OTPForm.module.css';

export const OTPForm = ({ otpRequiredStatus, onSubmit }: IProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  const methods = useForm<OTPFormSchema>({
    mode: 'onTouched',
    defaultValues: {
      otpCode: '',
      trustUserAgent: true,
    },
    resolver: zodResolver(schema),
  });

  const handleSubmit = async ({ otpCode, trustUserAgent }: OTPFormSchema) => {
    if (!/^\d{6}$/.test(otpCode)) return setError(true);
    try {
      const res = await AuthService.signIn({
        email: otpRequiredStatus.email,
        password: otpRequiredStatus.password,
        otp: otpCode,
        trustUserAgent,
      });
      onSubmit(res);
      AnalyticsService.trackCustomEvent('login_otp_success', {
        trust_device: trustUserAgent,
      });
    } catch (err: any) {
      AnalyticsService.trackCustomEvent('login_otp_failed');
      setError(true);
      methods.resetField('otpCode');
    }
  };

  return (
    <MFALayout>
      <FormProvider {...methods}>
        <div className={css.host}>
          <Title size="small">
            {t('authentication.signin.form.mfa.otp.title', {
              suffix: otpRequiredStatus.phoneLastDigits,
            })}
          </Title>
          <form
            className={css.form}
            onSubmit={methods.handleSubmit(handleSubmit)}
          >
            <div className={css.fields}>
              <div className={cx(css.digitsContainer)}>
                <ControlledDigitsField
                  autofocus
                  name="otpCode"
                  label={t('authentication.signin.form.mfa.otp.code_label')}
                  transform={(value: string) => {
                    setError(false);
                    return value;
                  }}
                  error={
                    error ? t('authentication.signin.form.mfa.otp.error') : ''
                  }
                />
              </div>
              <ControlledCheckbox
                name="trustUserAgent"
                variant="descriptive"
                label={t(
                  'authentication.signin.form.mfa.otp.trust_user_agent_label'
                )}
              />
              <Controller
                name="otpCode"
                render={({ field: { value }, formState: { isSubmitting } }) => {
                  return (
                    <Button
                      type="submit"
                      onClick={methods.handleSubmit(handleSubmit)}
                      disabled={!/^\d{6}$/.test(value)}
                      className={css.button}
                      loading={isSubmitting}
                    >
                      {t('authentication.signin.form.mfa.otp.submit')}
                    </Button>
                  );
                }}
              />
            </div>
          </form>
          <div className={css.resendContainer}>
            <ResendCode
              debounceTime={59}
              onResend={() =>
                AuthService.signIn({
                  email: otpRequiredStatus.email,
                  password: otpRequiredStatus.password,
                })
              }
            />
            <Text color="neutral20">
              <Trans i18nKey="authentication.signin.form.mfa.otp.support_warning" />
            </Text>
          </div>
        </div>
      </FormProvider>
    </MFALayout>
  );
};

interface IProps {
  onSubmit: (data: ISignInResponse) => void;
  otpRequiredStatus: IOTPRequiredStatus;
}

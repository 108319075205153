import JWT from 'jwt-decode';

interface Token {
  sub: string;
}

export const TOKEN = 'cvrflx_flightdeck_token';
export const REFRESH_TOKEN = 'cvrflx_flightdeck_refresh_token';
export const USER_AGENT_TOKEN = 'cvrflx_flightdeck_user_agent_token';
export const LANGUAGE = 'cvrflex_flightdeck_language';
export const FIRST_TIME_BENEFIT = 'cvrflex_flightdeck_benefit';
export const FRINGE_LIMIT = 'cvrflex_flightdeck_fringe_limit';
export const INSURANCE_UPGRADE_PLAN_BANNER_DISMISSED =
  'cvrflx_flightdeck_insurance_upgrade_plan_banner_dismissed';
export const ALERT_OFFBOARDING_MEAL_BALANCE_DISMISSED =
  'cvrflx_flightdeck_offboarding_meal_balance_alert_dismissed';

export const memoryStorage = (() => {
  const storage: Record<string, string> = {};
  return {
    setItem: (key: string, data: string) => {
      storage[key] = data;
    },
    getItem: (key: string) => storage[key],
    removeItem: (key: string) => {
      delete storage[key];
    },
  };
})();
const getStorage = () => {
  try {
    return localStorage;
  } catch {
    return memoryStorage;
  }
};
const getAuthToken = () => getStorage().getItem(TOKEN);
const setAuthToken = (token: string) => getStorage().setItem(TOKEN, token);
const getLanguage = () => getStorage().getItem(LANGUAGE);
const setLanguage = (language: string) =>
  getStorage().setItem(LANGUAGE, language);
const clearAuthToken = () => getStorage().removeItem(TOKEN);
const decodeAuthToken = (token: string) => {
  try {
    const decoded: Token = JWT(token);
    const tokenData = JSON.parse(decoded.sub);
    return tokenData;
  } catch {
    return null;
  }
};
const getRefreshToken = () => getStorage().getItem(REFRESH_TOKEN);
const setRefreshToken = (token: string) =>
  getStorage().setItem(REFRESH_TOKEN, token);

export const getUserAgentToken = () => getStorage().getItem(USER_AGENT_TOKEN);

export const setUserAgentToken = (token: string) =>
  getStorage().setItem(USER_AGENT_TOKEN, token);

export const clearUserAgentToken = () =>
  getStorage().removeItem(USER_AGENT_TOKEN);
const clearRefreshToken = () => getStorage().removeItem(REFRESH_TOKEN);
const updateTokens = (
  authToken: string,
  refreshToken: string,
  userAgentToken?: string
) => {
  setAuthToken(authToken);
  setRefreshToken(refreshToken);
  if (userAgentToken !== undefined) {
    setUserAgentToken(userAgentToken);
  }
};
const clearTokens = () => {
  clearAuthToken();
  clearRefreshToken();
};
const getFirstTimeBenefit = () => getStorage().getItem(FIRST_TIME_BENEFIT);
const setFirstTimeBenefit = () =>
  getStorage().setItem(FIRST_TIME_BENEFIT, 'false');

export const getFringeLimit = () => getStorage().getItem(FRINGE_LIMIT);

export const setFringeLimit = () => getStorage().setItem(FRINGE_LIMIT, 'true');

export const setInsuranceUpgradePlanBannerDismissed = (dismissed: boolean) => {
  if (dismissed) {
    getStorage().setItem(INSURANCE_UPGRADE_PLAN_BANNER_DISMISSED, 'true');
  } else {
    getStorage().removeItem(INSURANCE_UPGRADE_PLAN_BANNER_DISMISSED);
  }
};

export const getInsuranceUpgradePlanBannerDismissed = () => {
  return (
    getStorage().getItem(INSURANCE_UPGRADE_PLAN_BANNER_DISMISSED) === 'true'
  );
};

export const setAlertOffboardingMealBalanceDismissed = (dismissed: boolean) => {
  if (dismissed) {
    getStorage().setItem(ALERT_OFFBOARDING_MEAL_BALANCE_DISMISSED, 'true');
  } else {
    getStorage().removeItem(ALERT_OFFBOARDING_MEAL_BALANCE_DISMISSED);
  }
};

export const getAlertOffboardingMealBalanceDismissed = () => {
  return (
    getStorage().getItem(ALERT_OFFBOARDING_MEAL_BALANCE_DISMISSED) === 'true'
  );
};

export const LocalStorageService = {
  getAuthToken,
  setAuthToken,
  clearAuthToken,
  decodeAuthToken,
  getRefreshToken,
  setRefreshToken,
  clearRefreshToken,
  getUserAgentToken,
  setUserAgentToken,
  clearUserAgentToken,
  updateTokens,
  clearTokens,
  getLanguage,
  setLanguage,
  getFirstTimeBenefit,
  setFirstTimeBenefit,
  getFringeLimit,
  setFringeLimit,
  getInsuranceUpgradePlanBannerDismissed,
  setInsuranceUpgradePlanBannerDismissed,
};

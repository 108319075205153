import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import authContext from 'contexts/authentication/AuthContext';
import { useQueryParams } from 'hooks/useQueryParams';
import {
  IPhoneRequiredStatus,
  ISignInResponse,
} from 'integrations/menhir/employee/sessions/sessions.types';
import { MFAFlow } from './containers/MFAFlow/MFAFlow';
import { SignupForm } from './containers/SignupForm/SignupForm';

export const SignUp = () => {
  const history = useHistory();
  const { signIn: userSignIn, initialRoute } = useContext(authContext);
  const { queryParams } = useQueryParams<'token'>();
  const [MFAStatus, setMFAStatus] = useState<IPhoneRequiredStatus>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const token = queryParams.token ?? '';

  const onSubmit = async (response: ISignInResponse) => {
    if ('id' in response) {
      setIsSubmitting(true);
      const user = response;
      userSignIn(user);
      try {
        if (initialRoute) {
          history.push(initialRoute);
        } else {
          history.push(ROUTES.ROOT, { origin: 'login' });
        }
      } catch {
        history.push(ROUTES.PROFILE);
      }
    } else if (response.reason === 'PHONE_REQUIRED') {
      setMFAStatus(response);
    }
  };

  if (MFAStatus) {
    return <MFAFlow token={token} status={MFAStatus} onSubmit={onSubmit} />;
  }

  return (
    <SignupForm token={token} onSubmit={onSubmit} isSubmitting={isSubmitting} />
  );
};

import {
  IMenhirTokenData,
  IUser,
} from 'integrations/menhir/employee/sessions/sessions.types';

export const tokenToUser = (tokenUser: IMenhirTokenData): IUser => ({
  id: tokenUser.user_id,
  email: tokenUser.email,
  companyId: tokenUser.company_id,
  permissions: tokenUser.permissions,
});

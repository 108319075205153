const distinct = <T = string | number>(array: Array<T>) =>
  array.filter((element, idx) => array.indexOf(element) === idx);

/**
   call with filter to remove falesy values in a typesafe way
    */
export function removeFalsey<Item>(
  item: Item | false | 0 | '' | null | undefined
): item is Item {
  return !!item;
}

export default {
  distinct,
  removeFalsey,
};

export function orderBy<T>(
  array: T[],
  getter: (item: T) => number | string | Date | undefined,
  order: 'asc' | 'desc' = 'asc'
) {
  // sort copied array so we don't mutate original
  return array.concat().sort((a, b) => {
    let itemA = getter(a);
    let itemB = getter(b);

    if (typeof itemA === 'string' && typeof itemB === 'string') {
      itemA = itemA.toLowerCase();
      itemB = itemB.toLowerCase();
      return order === 'asc'
        ? itemA.localeCompare(itemB)
        : itemB.localeCompare(itemA);
    }

    if (itemA instanceof Date && itemB instanceof Date) {
      itemA = itemA.getTime();
      itemB = itemB.getTime();

      return order === 'asc' ? itemA - itemB : itemB - itemA;
    }

    if (typeof itemA === 'number' && typeof itemB === 'number') {
      return order === 'asc' ? itemA - itemB : itemB - itemA;
    }

    return 0;
  });
}

export function groupBy<T>(array: T[], keyGetter: (item: T) => string) {
  const groups: Record<string, T[]> = {};
  array.forEach((item) => {
    const key = keyGetter(item);
    if (key) {
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item);
    }
  });

  return groups;
}

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GenericModal, IconLock } from '@coverflex-tech/hypernova';
import { ROUTES } from 'constants/routes';
import css from './created-account-modal.module.css';

interface IProps {
  sso?: boolean;
}

const CreatedAccountModal = ({ sso }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onLogin = () => {
    history.push(sso ? ROUTES.SSO_SIGNIN : ROUTES.SIGNIN);
  };

  return (
    <GenericModal
      title={t('signup.created_modal.title')}
      iconBadge={
        <div className={css.badge}>
          <IconLock className={css.icon} />
        </div>
      }
      content={t(
        `signup.created_modal.${sso ? 'description_sso' : 'description'}`
      )}
      primaryAction={{
        onClick: onLogin,
        children: t('signup.created_modal.login_button'),
      }}
    />
  );
};

export default CreatedAccountModal;

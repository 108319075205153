import { transformExternalIntegrations } from 'integrations/menhir/external-integrations/external-integrations.dto';
import { IMenhirExternalIntegrations } from 'integrations/menhir/external-integrations/external-integrations.types';
import { menhirClient } from 'integrations/menhir/menhir.client';
import { MENHIR_ENDPOINTS } from 'integrations/menhir/menhir.endpoints';

export const getExternalIntegrations = () =>
  menhirClient
    .get<null, { external_integrations: IMenhirExternalIntegrations }>(
      MENHIR_ENDPOINTS.EXTERNAL_INTEGRATIONS
    )
    .then((envelope) =>
      transformExternalIntegrations(envelope.external_integrations)
    );

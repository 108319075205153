import { useCallback, useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { Button, Text } from '@coverflex-tech/hypernova';
import { AnalyticsService } from 'integrations/analytics/analytics.service';
import css from './ResendCode.module.css';

const toMinSecFormat = (totalSeconds: number) => {
  const numberFormatter = new Intl.NumberFormat('en-US', {
    minimumIntegerDigits: 2,
  });
  const totalMinutes = Math.floor(totalSeconds / 60);
  const seconds = numberFormatter.format(totalSeconds % 60);
  const minutes = numberFormatter.format(totalMinutes % 60);

  return `${minutes}:${seconds}`;
};

export const ResendCode = ({
  onResend,
  variant = 'text',
  debounceTime = 60,
  disabled,
}: IProps) => {
  const [resendTimeout, setResendTimeout] = useState(0);
  const intervalRef = useRef<number>();

  const onStartInterval = useCallback(() => {
    setResendTimeout(debounceTime);
    intervalRef.current = window.setInterval(() => {
      setResendTimeout((prev) => {
        const newVal = prev - 1;
        if (newVal <= 0) {
          window.clearInterval(intervalRef.current);
        }
        return newVal;
      });
    }, 1000);
  }, [debounceTime]);

  const onClickResend = async () => {
    onStartInterval();
    onResend?.();
    AnalyticsService.trackCustomEvent('login_otp_resend_clicked');
  };

  useEffect(() => {
    onStartInterval();
    return () => window.clearInterval(intervalRef.current);
  }, [onStartInterval]);

  return (
    <div className={css.root}>
      {variant === 'text' && (
        <>
          <Text bold>
            <Trans
              i18nKey={'authentication.signin.form.mfa.otp.code_not_arrived'}
            />
          </Text>
          {resendTimeout <= 0 ? (
            <Button onClick={onClickResend} variant="tertiary" size="sm">
              <Trans
                i18nKey={'authentication.signin.form.mfa.otp.code_resend'}
              />
            </Button>
          ) : (
            <Text bold>
              <Trans
                i18nKey={'authentication.signin.form.mfa.otp.code_resend_in'}
                values={{ time: toMinSecFormat(resendTimeout) }}
              />
            </Text>
          )}
        </>
      )}
      {variant === 'button' && (
        <>
          {resendTimeout <= 0 ? (
            <Button onClick={onClickResend} disabled={disabled}>
              <Trans
                i18nKey={'authentication.signin.form.mfa.otp.code_resend'}
              />
            </Button>
          ) : (
            <Text bold>
              <Trans
                i18nKey={'authentication.signin.form.mfa.otp.code_resend_in'}
                values={{ time: toMinSecFormat(resendTimeout) }}
              />
            </Text>
          )}
        </>
      )}
    </div>
  );
};

export interface IProps {
  onResend?: () => void;
  variant?: 'button' | 'text';
  debounceTime?: number;
  disabled?: boolean;
}

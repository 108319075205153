import {
  IFeatures,
  IMenhirFeatures,
} from 'integrations/menhir/employee/features/features.types';

export const transformFeatures = (
  menhirFeatures: IMenhirFeatures
): IFeatures => ({
  FF_BENEFITS_OPTIN: Boolean(menhirFeatures.benefits_opt_in),
  FF_BUDGET_WITH_APPLICATION: Boolean(menhirFeatures.budget_with_application),
  FF_BUONI_PASTO_NETWORK: Boolean(menhirFeatures.ff_buoni_pasto_network),
  FF_CARD: Boolean(menhirFeatures.card),
  FF_DISCOUNTS: Boolean(menhirFeatures.discounts),
  FF_EMPLOYEE_CREDITS: Boolean(menhirFeatures.employee_credits),
  FF_FILE_BANKING_PROVIDER: Boolean(menhirFeatures.file_banking_provider),
  FF_RECARDING_IT: Boolean(menhirFeatures.ff_recarding_it),
  FF_REIMBURSEMENTS_MULTIPLE_FILES_UPLOAD: Boolean(
    menhirFeatures.reimbursements_multiple_files_upload
  ),
  FF_SHOW_BANNER_FOR_RETIREMENT_INVESTMENT: Boolean(
    menhirFeatures.ff_show_banner_for_retirement_investment
  ),
  FF_CAN_ADD_FAMILY_MEMBERS: Boolean(menhirFeatures.ff_can_add_family_members),
});
